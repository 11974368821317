export function looksLikeCreditCard(value: string): boolean {
	//please keep me updated along with fastpay, fastbill, loggedinweb, merchant portal and webgiving
	const creditCardRegex = /([2-6](\d[ \-]*){11,18})/i;
	return creditCardRegex.test(value);
}

export function looksLikeEmail(value: string): boolean {
	const emailRegex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
	return emailRegex.test(value);
}

