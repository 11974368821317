import * as React from 'react';
import { observer } from 'mobx-react';
import { AppConnectMainViewModel } from './app-connect-main-view-model';
import { BrandingTheme } from '../../WebGiving/branding/branding-theme';
import { AppConnectMainHeader } from './app-connect-main-header';
import { DownloadLinks } from '../components/download-links/download-links';
import { MobilePhoneControl } from '../../WebGiving/components/mobile-phone-control/mobile-phone-control';
import { Panel, PanelType } from '../../WebGiving/components/panel';
import { FormControl } from '../../WebGiving/components/form-control/form-control';
import { Button } from '../../WebGiving/primitives/button/button';
import { FooterContainer, FooterSeparator, FooterPushpaySection} from '../../WebGiving/main/webgiving-main-footer';
import { NotificationController } from '../../WebGiving/components/notification/notification';
import { Form } from '../../WebGiving/primitives/form';
import { Alert } from '../../WebGiving/components/alert/alert';
import { classNames } from '../../Shared/utils/classnames';
import { SvgIcon } from '../../WebGiving/components/svg-icon';
import * as mainStyles from '../../WebGiving/main/webgiving-main.less';
import * as styles from './app-connect-main.less';
import iconDownload from '../../WebGiving/assets/svg/icon-download.svg';

@observer
export class AppConnectMain extends React.Component<{ vm: AppConnectMainViewModel}, {}> {
	render() {
		const { mobileCountry, mobileNumber, isProcessing, sendingTextBlocked, serverError, notification, viewData } = this.props.vm;
		const {	Merchant: merchant,	Theme: theme, HelpCenterLink: helpCenterLink } = viewData;

		return (
			<div>
				<BrandingTheme theme={theme}/>
				<AppConnectMainHeader merchant={merchant} logoUrl={theme.MerchantLogoUrl} />
				<main className={mainStyles.main}>
					<NotificationController viewModel={notification} />
					<div className={classNames('container', mainStyles.container)}>
						<Form onSubmit={this.onSubmit}>
							<Panel panelType={PanelType.Highlight}>
								<div className="panel-heading text-center">
									<div className={styles.downloadIcon}>
										<SvgIcon svg={iconDownload} scale={1.4} />
									</div>
									<h1>Get {merchant.AppName} app</h1>
									<p className={styles.lead}>
										{merchant.AppNameCapitalized} app is your fastest, most convenient way to make a {merchant.PaymentLabel.NounLowerCase} and get the most up to date and relevant information about what's going on at {merchant.TradingName}. Send this page to a mobile device to download the app.
									</p>
									{serverError && <Alert content={serverError} />}
									<div className="row">
										<div className="col-sm-8 col-centered text-left">
										<FormControl label="Mobile number" validationError={mobileNumber.validationError}>
											<MobilePhoneControl
												numberField={mobileNumber}
												countryField={mobileCountry}
												acceptanceTestTargetId="Mobile number"
												aria-required={true}
											/>
										</FormControl>
										</div>
									</div>
									<div className="row">
										<div className={classNames('col-xs-12', styles.sendThisPageBtn)}>
											<Button className="button"
												submit={true}
												acceptanceTestTargetId="Send code"
												isLoading={isProcessing}
												isBlocked={sendingTextBlocked}>
												Send this page
											</Button>
										</div>
									</div>
								</div>
								<div className={classNames('panel-footer', styles.panelFooter)}>
									<DownloadLinks merchant={merchant} />
								</div>
							</Panel>
						</Form>
					</div>
					<FooterContainer>
						<FooterSeparator />
						<FooterPushpaySection helpCenterLink={helpCenterLink} />
					</FooterContainer>
				</main>
			</div>
		);
	}

	private onSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
		this.props.vm.sendAppLink();
	}
}
