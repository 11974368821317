import * as React from 'react';
import { container, icon, darker, small, placementRight, placementLeft, squished } from './control-with-icon.less';

export class FormControlWithIcon extends React.Component<{ iconPlacement: 'left' | 'right', squished?: boolean, className?: string }> {
	render() {
		const classes = [container];

		switch (this.props.iconPlacement) {
			case 'left':
				classes.push(placementLeft);
				break;
			case 'right':
				classes.push(placementRight);
				break;
			default:
				throw new Error(`iconPlacement ${this.props.iconPlacement} is not supported`);
		}

		if (squished) {
			classes.push(squished);
		}

		if (this.props.className) {
			classes.push(this.props.className);
		}

		return (
			<div className={classes.join(' ')}>
				{this.props.children}
			</div>
		);
	}
}

export class FormControlIcon extends React.Component<{ darker?: boolean, small?: boolean }> {
	render() {
		const classes = [
			icon,
			this.props.darker ? darker : null,
			this.props.small ? small: null,
		].join(' ');
		return <span className={classes}>{this.props.children}</span>;
	}
}
