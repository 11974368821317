import * as React from 'react';
import { observer } from 'mobx-react';
import { ITextboxCommonProps, Textbox } from '../../primitives/textbox';
import { FormField } from '../../components/form-field';
import { RuleType } from '../../validation/validation-rules';

export type ITextboxFieldProps = ITextboxCommonProps & { formField: FormField<string> };

@observer
export class TextboxField extends React.Component<ITextboxFieldProps, {}> {
	render() {
		const { formField, ...rest } = this.props;
		const { value, updateValue, revalidate, hasValidationError, hasValidationRule } = formField;
		const ariaRequired = hasValidationRule(RuleType.Required);
		return (
			<Textbox
				{...rest}
				value={value}
				onChange={updateValue}
				onBlur={revalidate}
				aria-invalid={hasValidationError}
				dataFieldInvalid={formField.hasValidationError}
				aria-required={ariaRequired}
			/>
		);
	}
}
