import * as React from 'react';

export enum PanelType {
	Highlight,
	White,
}

export class Panel extends React.Component<{
	panelType?: PanelType,
	className?: string,
	responsive?: boolean,
}, {}> {
	render() {
		const { panelType, children, responsive, className } = this.props;
		const classNames = [ 'panel' ];

		if (responsive) {
			classNames.push('panel-responsive');
		}

		if (className) {
			classNames.push(className);
		}

		switch (panelType) {
			case PanelType.Highlight:
				classNames.push('panel-highlight');
				break;
			case PanelType.White:
				classNames.push('panel-white');
				break;
		}

		return (
			<div className={classNames.join(' ')}>
				{children}
			</div>
		);
	}
}
