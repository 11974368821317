import React, { createContext, FC, useContext, ReactNode } from 'react';

const ShouldTranslateContext = createContext<boolean>(false);

export const ShouldTranslateProvider: FC<{ hasEntitlement?: boolean }> = ({
	hasEntitlement = false,
	children,
}) => (
	<ShouldTranslateContext.Provider value={hasEntitlement}>
		{children}
	</ShouldTranslateContext.Provider>
);

export function useShouldTranslate() {
	return useContext(ShouldTranslateContext);
}

export const ShouldTranslate: FC<{ children: (shouldTranslate: boolean) => ReactNode }> = ({ children }) => {
	const shouldTranslate = useShouldTranslate();
	return (<>{children(shouldTranslate)}</>);
};

