import React, { FC, useCallback, Suspense } from 'react';
import { isFunction } from '../../Shared/utils/is-function';
import { SvgIcon } from '../components/svg-icon';
import { FormControlWithIcon, FormControlIcon } from '../components/control-with-icon/control-with-icon';
import icon from '../assets/svg/icon-chevron-down.svg';
import { useTranslation, useShouldTranslate } from '../translation';
import { Skeleton } from '../components/skeleton/skeleton';
import { IParams } from '../components/text';
import { interpolate } from '../utils/interpolate-text';

export interface IOptionProps {
	value: any;
	label: string;
	disableTranslation?: boolean;
	translationParams?: IParams;
	disabled?: boolean;
};

export interface ISelectCommonProps<T> {
	acceptanceTestTargetId: string;
	formControlId?: string;
	options: IOptionProps[];
	className?: string;
	additionalClassNames?: string;
	dataFieldInvalid?: boolean;
}

export interface ISelectPrimitiveProps<T> {
	value: T;
	onChange?: (value: T) => void;
	onClick?: () => void;
	onBlur?: () => void;
}

export type ISelectProps<T> = ISelectCommonProps<T> & ISelectPrimitiveProps<T>;

export const Select: FC<ISelectProps<any>> = ({
	value,
	options,
	formControlId,
	className,
	additionalClassNames,
	dataFieldInvalid,
	acceptanceTestTargetId,
	children,
	onChange,
	onClick,
	...rest
}) => {
	const shouldTranslate = useShouldTranslate();

	const handleChange = useCallback((event: React.FormEvent<HTMLSelectElement>) => {
		if (isFunction(onChange)) {
			const index = parseInt(event.currentTarget.value, 10);
			onChange(options[index].value);
		}
	}, [onChange, options])

	// get the array index of the current value in options, defaults to 0
	const selectedIndex = options.reduce((a, x, i) => (x.value === value) ? i : a, 0);

	return (
		<Suspense fallback={<Skeleton />}>
			<FormControlWithIcon className={className} iconPlacement="right">
				<select
					{...rest}
					className={`form-control ${
						additionalClassNames ? additionalClassNames : ""
					}`}
					id={formControlId}
					value={selectedIndex}
					data-field-invalid={dataFieldInvalid ? true : null}
					data-pp-at-target={acceptanceTestTargetId}
					onChange={handleChange}
					onClick={onClick}
				>
					{options.map((option, index) => {
						if (shouldTranslate) {
							return (
								<TranslatedOption
									{...option}
									key={option.value}
									index={index}
									isSelected={selectedIndex === index}
								/>
							);
						} else {
							let name = option.label;
							if ( option.translationParams && Object.keys(option.translationParams).length ) {
								name = interpolate(option.label, option.translationParams );
							}

							return (
								<option key={option.value} value={index} disabled={option.disabled}>
									{name}
								</option>
							);
						}
					})}
				</select>
				<FormControlIcon darker>
					<SvgIcon svg={icon} />
				</FormControlIcon>
			</FormControlWithIcon>
		</Suspense>
	);
}

export const TranslatedOption: FC<IOptionProps & { index: number, isSelected: boolean }> = ({
	label,
	disableTranslation,
	translationParams,
	index,
	isSelected,
	disabled,
}) => {
	const { translate } = useTranslation();

	return (
		<option value={index} selected={isSelected} disabled={disabled}>
			{disableTranslation ? label : translate(label as any, translationParams)}
		</option>
	);
};
