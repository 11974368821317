const parseScientificNotationRegex = /([\d|\.]+)e([\+|-]\d+)/;

export module FloatMath {

	// more info at http://www.jacklmoore.com/notes/rounding-in-javascript/
	export function round(num: number, precision: number): number {
		return pow10(Math.round(pow10(num, precision)), -precision);
	}

	export function numberToFixedString(num: number, precision: number) {
		// toFixed only returns numbers greater than 1e21 having a scientific notation
		// we need to format them ourselves
		// numbers like that cannot contain any decimal places because float isn't large enough

		// respectfully copied from https://stackoverflow.com/a/1685917 and modified to handle only large numbers
		let exponent = parseInt(num.toString().split('+')[1]);
		if (exponent > 20) {
			exponent -= 20;
			const withoutPrecision = pow10(num, -exponent) + (new Array(exponent + 1).join('0'));
			if (precision === 0) {
				return withoutPrecision;
			}
			return `${withoutPrecision}.${new Array(precision + 1).join('0')}`;
		}

		return num.toFixed(precision);
	}

	function pow10(num: number, power: number) {
		if (num.toString().indexOf('e') !== -1) {
			const [, coefficient, exponent] = <RegExpMatchArray>num.toString().match(parseScientificNotationRegex);
			return +(coefficient + 'e' + (+exponent + power));
		}

		return +(num + 'e' + power);
	}
}
