import * as mobx from 'mobx';

import { post } from '../Shared/utils/ajax-client';
import { AjaxUtils } from '../Shared/utils/ajax-utils';
import { ICancellablePromise } from '../Shared/utils/cancellable-promise';
import { AppConnectApiConfig } from './app-connect-generated';

export interface IApiAction<TRequest, TResponse = any> {
	readonly url: (model: TRequest) => string;
	request: TRequest;
	response: TResponse;
}

export type AppConnectActionRequest<TActionKey extends keyof AppConnectApiConfigActions> = AppConnectApiConfigActions[TActionKey]['request'];
export type AppConnectActionResponse<TActionKey extends keyof AppConnectApiConfigActions> = AppConnectApiConfigActions[TActionKey]['response'];

export type AppConnectApiConfigActions = typeof AppConnectApiConfig['actions'];

export type AppConnectDataService = {
	[x in keyof AppConnectApiConfigActions]: (request: AppConnectActionRequest<x>) => ICancellablePromise<AppConnectActionResponse<x>>;
};

const options = {
	timeout: 20000,
	newCSRFToken: '',
};

let instance: AppConnectDataService | null = null;

function createActionHandler<TKey extends keyof AppConnectApiConfigActions>(actionKey: TKey) {
	const actionConfig = AppConnectApiConfig.actions[actionKey] as any;

	return (request: any) => {
		request = mobx.isObservable(request) ? mobx.toJS(request) : { ...request };

		return post(actionConfig.url(request), request, {
			timeout: options.timeout,
			baseUrl: AjaxUtils.resolveBaseUrl(AppConnectApiConfig.defaultBaseUrl),
			antiForgeryToken: options.newCSRFToken,
		});
	};
}

function createAppConnectDataService(): AppConnectDataService {
	return (Object.keys(AppConnectApiConfig.actions) as Array<keyof AppConnectApiConfigActions>)
		.reduce<AppConnectDataService>((acc: any, actionKey: keyof AppConnectApiConfigActions) => {
			acc[actionKey] = createActionHandler(actionKey);
			return acc;
		}, {} as AppConnectDataService);
}

export function getAppConnectDataService() {
	if (instance === null) {
		instance = createAppConnectDataService();
	}

	return instance;
}

export function setNewCRSFoken(token: string) {
	options.newCSRFToken = token;
}

export function mockAppConnectDataService(mockDataService: AppConnectDataService) {
	instance = mockDataService;
}
