import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { responsiveHelper } from '../../Shared/helpers/responsive-helper';

let focusOnMountPrevented = false;
export function preventFocusOnMount() {
	focusOnMountPrevented = true;
}

export class FocusOnMount extends React.Component<{ delay?: number }> {
	private timeout: number | undefined;

	render() {
		const { delay, children, ...rest } = this.props;
		return React.cloneElement((React.Children.only(this.props.children) as React.ReactElement), { ...rest });
	}

	componentDidMount() {
		if (focusOnMountPrevented) {
			return;
		}

		const { delay } = this.props;

		if (responsiveHelper.isSm || responsiveHelper.isXs) {
			return;
		}

		if (delay) {
			this.timeout = window.setTimeout(this.focusElement, delay);
		} else {
			this.focusElement();
		}
	}

	componentWillUnmount() {
		if (this.timeout) {
			clearTimeout(this.timeout);
		}
	}

	private focusElement = () => {
		const element = ReactDOM.findDOMNode(this) as HTMLElement;

		if (element) {
			if (document.activeElement !== document.body) {
				console.log('cancelling FocusOnMount because another element is focused', document.activeElement);
				return;
			}

			element.focus();
		}
	}
}
