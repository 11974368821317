﻿; (function () {

	window.reportUnhandledRejection = function (error, customData) {
		// this function expects an error
		// let's try to convert it to an error if it's not
		if (typeof error === 'string') {
			error = new Error(error);
		} else if(isPostError(error)) {
			customData = Object.assign({}, customData, {
				postError: error
			});
			error = error.asJsError;
		} else if (!isErrorLike(error)) {
			customData = Object.assign({}, customData, { valueOfErrorParameter: error });
			error = new Error('The type of \'error\' parameter is unsupported. Please check valueOfErrorParameter in customData.')
		}

		if (error.sagaStack) {
			customData = Object.assign({}, customData, { sagaStack: error.sagaStack });
		}
		if (error.stack) {
			// raygun does not always provide the best stack available
			// let's always send browsers representation of the stack
			customData = Object.assign({}, customData, { stack: error.stack });
		}
		window.dispatchEvent(new CustomEvent('pushpayUnhandledRejection', { detail: error.message }));
		console['error'](error, customData);

		var errorPayload = error;
		if (customData) {
			errorPayload = {
				error: error,
				customData: customData
			};
		}

		if (typeof DEBUG === 'boolean' && DEBUG) {
			return;
		}

		if (window.rg4js) {
			rg4js('send', errorPayload);
		} else if (window.Raygun) {
			Raygun.send(errorPayload);
		} else {
			console.warn('Cannot find Raygun');
		}
	};

	window.addEventListener("unhandledrejection", function (event) {
		var reason = event.reason;
		if (reason) {
			window.reportUnhandledRejection(reason, { event: event });
		}
	});

	function isErrorLike(error) {
		return error && error.message;
	}

	function isPostError(error) {
		return error && error.isPostError;
	}

	if (typeof DEBUG === 'boolean' && DEBUG && window.rg4js) {
		rg4js('detach');
	}
})();
