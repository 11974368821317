
declare const UNIT_TEST: boolean;

let cardBrandIcons;
if (UNIT_TEST) {
	const module = function () {
		return {};
	} as any;

	module.keys = () => [];
	cardBrandIcons = module;

} else {
	cardBrandIcons = require.context('../assets/svg/', true, /paymentmethod-.*\.svg$/);
}

export default cardBrandIcons as  __WebpackModuleApi.RequireContext;
