import { action, computed, observable, runInAction } from 'mobx';
import { AppConnectViewModel, ModelMetadata, SendAppLinkRequest } from '../app-connect-generated';
import { FormField } from '../../WebGiving/components/form-field';
import { ValidationState, validateFields } from '../../WebGiving/validation/view-model-validator';
import { createRulesFromMetadata } from '../../WebGiving/validation/validation-rules';
import { NotificationViewModel } from '../../WebGiving/components/notification/notification';
import { PhoneNumberUtils } from '../../WebGiving/utils/phone-number-utils';
import { getAppConnectDataService } from '../app-connect-data-service';

const defaultErrorMessage = `Our system encountered an unexpected error. We're currently looking into it. Please try again soon.`;

export class AppConnectMainViewModel {
	readonly blockSendingTextTimeout = 20000;

	@observable
	notification: NotificationViewModel;

	@observable
	mobileNumber: FormField<string>;

	@observable
	mobileCountry: FormField<any>;

	@observable
	isProcessing: boolean = false;

	@observable
	sendingTextBlocked: boolean = false;

	@observable
	serverError: string | null;

	@observable
	viewData: AppConnectViewModel;

	nonce: string;

	private readonly merchantId: number;

	private readonly payerId?: number | null;

	private readonly correlationId: string;

	@action.bound
	blockSendingText() {
		this.sendingTextBlocked = true;
		window.setTimeout(this.unblockSendingText, this.blockSendingTextTimeout);
	}

	constructor(viewData: AppConnectViewModel) {
		this.viewData = viewData;
		this.merchantId = viewData.Merchant.Id;

		if (viewData.QueryData) {
			const { PayerId: payerId, CorrelationId: correlationId } = viewData.QueryData;
			this.payerId = payerId;
			this.correlationId = correlationId;
		}

		const mobileNumber = viewData.QueryData && viewData.QueryData.ConfirmedMobileNumber || '';
		const defaultCountry = viewData.Merchant.Country;
		const country = PhoneNumberUtils.getRegionForNumber(mobileNumber, defaultCountry);

		this.mobileCountry = FormField.Unvalidated(typeof country === 'number' ? country : defaultCountry);
		this.mobileNumber = new FormField<string>(PhoneNumberUtils.format(mobileNumber, country), createRulesFromMetadata(ModelMetadata.SendAppLinkRequest.MobileNumber));
		this.mobileNumber.updateSubject.register(this.synchronisePhoneCountryField);
	}

	validate(): ValidationState {
		return validateFields(this);
	}

	@action.bound
	sendAppLink() {
		const validationState = this.validate();
		if (!validationState.isValid) {
			return;
		}

		this.serverError = null;
		this.isProcessing = true;

		getAppConnectDataService()
			.sendAppConnectLink({ request: this.sendAppLinkRequest })
			.then(response => {
				runInAction(() => {
					if (response.Success) {
						this.showSmsSuccessfullySentNotification();
					} else {
						this.mobileNumber.setServerSideValidationError(response.Reason);
					}
					this.isProcessing = false;
				});
			}).catch(reason => {
				runInAction(() => {
					this.serverError = defaultErrorMessage;
					this.isProcessing = false;
					window.reportUnhandledRejection(reason);
				});
			});
	}

	@computed
	private get sendAppLinkRequest(): SendAppLinkRequest {
		return {
			MobileNumber: this.mobileNumber.value,
			Country: this.mobileCountry.value,
			MerchantId: this.merchantId,
			PayerId: this.payerId,
			CorrelationId: this.correlationId,
		};
	}

	@action.bound
	private unblockSendingText() {
		this.sendingTextBlocked = false;
	}

	@action.bound
	private showSmsSuccessfullySentNotification() {
		this.notification = new NotificationViewModel('We have sent a download link to your phone', 10);
	}

	//todo: refactor into mobile number view model
	@action.bound
	private synchronisePhoneCountryField(field: FormField<string>) {
		const value = field.value;
		const newRegion = PhoneNumberUtils.getRegionForNumber(value, this.mobileCountry.value);

		if (typeof newRegion === 'number') {
			this.mobileCountry.updateValue(newRegion);
		}
	}
}
