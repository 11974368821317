// TODO PP-16776: move LoggedInWeb/helpers/formatter here once we complete webpack LoggedInWeb migration

export * from '../../LoggedInWeb/helpers/formatter';

export function stripNonNumber(input: string): string {
	return input.replace(/[^\d]/g, '');
}

export function centsToDollarFormat(input: string): number {
	return Number(input.replace(/[0-9]{2}$/, v => '.' + v));
}
