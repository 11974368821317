var alerts = document.querySelectorAll('.ga-alert');
window._gaq = window._gaq || [];
var tagId = window._ppGaTrackingId || 'G-HF7ERE6B6Z';
window._gaq.push(['_setAccount', tagId]);
window._gaq.push(['_trackPageview']);
window._usingGTag = false;

(function () {
	if (tagId.charAt(0) === 'G') {
		window._usingGTag = true;
		var gtagScript = document.createElement('script'); gtagScript.type = 'text/javascript'; gtagScript.async = true;
		gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + tagId;
		var gdoc = document.getElementsByTagName('script')[0]; gdoc.parentNode.insertBefore(gtagScript, gdoc);

		window.dataLayer = window.dataLayer || [];
		window._gtag = window._gtag = function () {
			window.dataLayer.push(arguments);
		};

		window._gtag('js', new Date());
		window._gtag('config', tagId);
		window._gtag('event', 'page_view');
	} else {
		var ga = document.createElement('script'); ga.type = 'text/javascript'; ga.async = true;
		ga.src = ('https:' === document.location.protocol ? 'https://ssl' : 'http://www') + '.google-analytics.com/ga.js';
		var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ga, s);
	}
})();

var ppTracking = (function () {
	var init = function () {

		// Track pixel density ratio
		var pixelRatio = window.devicePixelRatio ? String(window.devicePixelRatio) : "1";
		if (window._usingGTag) {
			window._gtag('event', 'pixel_ratio', { ratio: pixelRatio });
		} else {
			window._gaq.push(['_setCustomVar', 1, 'Pixel Ratio', pixelRatio, 1]);
		}
	};

	return { init: init };
}());

document.addEventListener('DOMContentLoaded', function (event) {
	ppTracking.init();
});

if (alerts) {
	Array.prototype.forEach.call(alerts, function (el, i) {
		el.addEventListener('click', function (event) {
			if (window._usingGTag) {
				window._gtag('event', 'ga_alert', { title: document.title, text: el.innerText });
			} else {
				window._gaq.push(['_trackEvent', document.title, el.innerText]);
			}
		});
	});
}
