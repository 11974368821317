import * as React from 'react';
import { Text, IParams } from '../components/text';

export interface IFormControlLabelProps {
	formControlId: string;
	className?: string;
	children: string
	disableTranslation?: boolean
	translationParams?: IParams;
}

export class FormControlLabel extends React.Component<IFormControlLabelProps, {}> {
	render() {
		const { children, formControlId, className, disableTranslation, translationParams } = this.props;
		let classNames = [ 'form-control-label' ];

		if (className) {
			classNames.push(className);
		}

		return (
			<label className={classNames.join(" ")} htmlFor={formControlId}>
				{disableTranslation ?  children  : <Text params={translationParams}>{children}</Text>}
			</label>
		);
	}
}
