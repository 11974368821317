import * as React from 'react';
import { observer } from 'mobx-react';
import { Checkbox, ICheckboxCommonProps } from '../../primitives/checkbox/checkbox';
import { FormField } from '../../components/form-field';
import { isFunction } from '../../../Shared/utils/is-function';
import { IParams } from '../../components/text';

export type ICheckboxFieldProps = ICheckboxCommonProps & {
	formField: FormField<boolean>;
	onChange?: (value: boolean) => void;
	ariaDescribedBy?: string;
	translationParams?: IParams;
};

@observer
export class CheckboxField extends React.Component<ICheckboxFieldProps, {}> {
	render() {
		const { value, validationError } = this.props.formField;

		return (
			<Checkbox
				{...this.props}
				value={value}
				onChange={this.handleChange}
				validationError={validationError}
			/>
		);
	}

	handleChange = (value: boolean) => {
		const { updateValue, revalidate } = this.props.formField;
		updateValue(value);
		revalidate();

		if (isFunction(this.props.onChange)) {
			this.props.onChange(value);
		}
	}
}
