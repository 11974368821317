import * as React from 'react';
import { SvgIcon } from '../svg-icon';
import { infoIcon } from './custom-svg.less';

import info from '../../assets/svg/icon-info.svg';

export const InformationIcon: React.StatelessComponent = (props) => {
	return (<SvgIcon svg={info} className={infoIcon}/>);

};
