import { ModelMetadata } from '../webgiving-generated';
import { looksLikeCreditCard } from '../../Shared/helpers/validationhelper';
import { IParams } from '../components/text';

export type ValidatorFunc<T> = {
	(value: T): FieldValidationResult;
	ruleType: RuleType;
};

export function combineValidators<T>(...validators: ValidatorFunc<T>[]): ValidatorFunc<T> {
	const ruleType = validators.reduce((flags, v) => {
		flags |= v.ruleType;
		return flags;
	}, RuleType.None);

	return Object.assign((value: T) => {
		for (let v of validators) {
			const result = v(value);
			if (!result.isValid) {
				return result;
			}
		}

		return fieldValidationSuccess;
	}, { ruleType });
}

type getValidationResult<T> = (value: T) => FieldValidationSuccess | FieldValidationFailure;

export function createValidatorFunc<T>(getValidationResult: getValidationResult<T>, ruleType: RuleType): ValidatorFunc<T> {
	return Object.assign((value: T) => getValidationResult(value), { ruleType });
}

export function regexValidator(regex: RegExp, errorMessage: string): ValidatorFunc<string> {
	return createValidatorFunc(s => {
		if (!regex.test(s)) {
			return new FieldValidationFailure(RuleType.Regex, errorMessage);
		}
		return fieldValidationSuccess;
	}, RuleType.Regex);
}

export function enforceTrueValidator(errorMessage: string): ValidatorFunc<boolean> {
	return createValidatorFunc(value => {
		if (value) {
			return fieldValidationSuccess;
		}
		return new FieldValidationFailure(RuleType.EnforceTrue, errorMessage);
	}, RuleType.EnforceTrue);
}

export function requiredValidator<T>(errorMessage: string): ValidatorFunc<T> {
	return createValidatorFunc(s => {
		if (s === null || s === undefined) {
			return new FieldValidationFailure(RuleType.Required, errorMessage);
		}
		if (typeof s === 'string' && s.trim() === '') {
			return new FieldValidationFailure(RuleType.Required, errorMessage);
		}

		return fieldValidationSuccess;
	}, RuleType.Required);
}

export function valueInRange<T extends number | string>(allowedValues: Array<T>, errorMessage: string): ValidatorFunc<T> {
	return createValidatorFunc(value => {
		if (allowedValues.indexOf(value) === -1) {
			return new FieldValidationFailure(RuleType.Required, errorMessage);
		}

		return fieldValidationSuccess;
	}, RuleType.Custom);
}

export function lengthValidator(min: number, max: number, errorMessage: string): ValidatorFunc<string> {

	if (min > max) {
		throw new Error('Min length must be smaller than max length');
	}

	if (max === 0) {
		throw new Error('Max length must be greater than zero');
	}

	return createValidatorFunc(s => {
		if (s === '' || s === undefined || s === null) {
			return fieldValidationSuccess;
		}

		const trimmedString = s.trim();

		const tooLong = trimmedString.length > max;
		const tooShort = trimmedString.length < min;
		if (tooShort || tooLong) {
			return new FieldValidationFailure(RuleType.Length, errorMessage);
		}
		return fieldValidationSuccess;
	}, RuleType.Length);
}

export function emailValidator(errorMessage: string): ValidatorFunc<string> {
	return createValidatorFunc(s => {
		if (s === null || s === undefined || s === '') {
			return fieldValidationSuccess; // Not this validator's job to police whether the field is filled out or not
		}
		if (typeof s !== 'string') {
			throw new Error('emailValidator requires an argument of type string');
		}
		// Lets ignore leading and trailing white space
		s = s.trim();
		// Some simple checks to cover cases that the regex below can't deal with:
		const [localPart, domain] = s.split('@');
		if (localPart) {
			if (localPart[0] === '.' || localPart[localPart.length - 1] === '.') {
				return new FieldValidationFailure(RuleType.Email, errorMessage);
			}
			if (localPart.indexOf('..') > -1) {
				return new FieldValidationFailure(RuleType.Email, errorMessage);
			}
		}
		if (domain && domain.indexOf('.') === -1) {
			return new FieldValidationFailure(RuleType.Email, errorMessage);
		}

		// Regex implementation copied from https://github.com/jquery-validation/jquery-validation/blob/master/src/core.js
		// since that's the library we were using previously
		const emailRegex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
		if (emailRegex.test(s)) {
			return fieldValidationSuccess;
		}
		return new FieldValidationFailure(RuleType.Email, errorMessage);
	}, RuleType.Email);
}

type DateValidatorParam = Date | null;

export function dateValidator(errorMessage: string): ValidatorFunc<DateValidatorParam> {
	return createValidatorFunc(d => {
		if (d === null || d === undefined) {
			return fieldValidationSuccess; // Not this validator's job to police whether the field is filled out or not
		}

		if (d instanceof Date) {
			if (/Invalid|NaN/.test(d.toString())) {
				// Yes, it's a date object, but it has a bogus value.
				return new FieldValidationFailure(RuleType.Date, errorMessage);
			}
			return fieldValidationSuccess;
		}
		throw new Error('dateValidator requires an argument of type Date');
	}, RuleType.Date);
}

type NumberValidatorParam = number | null;

export function numberValidator(errorMessage: string): ValidatorFunc<NumberValidatorParam> {
	return createValidatorFunc(n => {
		if (n === null || n === undefined) {
			return fieldValidationSuccess; // Not this validator's job to police whether the field is filled out or not
		}
		if (typeof n === 'number') {
			if (isNaN(n)) {
				return new FieldValidationFailure(RuleType.Number, errorMessage); // It's literally not a number :(
			}
			return fieldValidationSuccess;
		}
		throw new Error('numberValidator requires an argument of type number');
	}, RuleType.Number);
}

export function rangeValidator<NumberValidatorParam>(min: number, max: number, errorMessage: string): ValidatorFunc<NumberValidatorParam> {

	if (min > max) {
		throw new Error('Min must be smaller than max');
	}

	return createValidatorFunc(n => {
		if (n === null || n === undefined) {
			return fieldValidationSuccess; // Not this validator's job to police whether the field is filled out or not
		}
		if (typeof n === 'number') {
			if (isNaN(n)) {
				return new FieldValidationFailure(RuleType.Range, errorMessage);
			}
			if (n < min || n > max) {
				return new FieldValidationFailure(RuleType.Range, errorMessage);
			}
			return fieldValidationSuccess;
		}
		throw new Error('rangeValidator requires an argument of type number');
	}, RuleType.Range);
}

function noCreditCardDetailsValidator(errorMessage: string): ValidatorFunc<string> {
	return createValidatorFunc(value => {
		if (looksLikeCreditCard(value)) {
			return new FieldValidationFailure(RuleType.Custom, errorMessage);
		}
		return fieldValidationSuccess;
	}, RuleType.Custom);
}

export function alwaysValid<T>(): ValidatorFunc<T> {
	return Object.assign((s: T) => fieldValidationSuccess, { ruleType: RuleType.None });
}

export enum RuleType {
	None = 0,
	Regex = 1 << 0,
	Required = 1 << 1,
	Length = 1 << 2,
	Number = 1 << 3,
	Date = 1 << 4,
	Email = 1 << 5,
	Range = 1 << 6,
	AlwaysValid = 1 << 7,
	EnforceTrue = 1 << 8,
	Server = 1 << 9,
	Custom = 1 << 10,
}

export function createRulesFromMetadata<T>(metadata: ModelMetadata.IPropertyMetadata): ValidatorFunc<T> {
	if (!metadata.validationRules) {
		return alwaysValid();
	}

	let validators = [];
	let skipSanitization = false;
	for (let key in metadata.validationRules) {
		const rule = metadata.validationRules[key];
		if (key === 'length') {
			validators.push(lengthValidator(rule.parameters.min, rule.parameters.max, rule.errorMessage));
		} else if (key === 'required') {
			validators.push(requiredValidator(rule.errorMessage));
		} else if (key === 'email') {
			validators.push(emailValidator(rule.errorMessage));
		} else if (key === 'date') {
			validators.push(dateValidator(rule.errorMessage));
		} else if (key === 'number') {
			validators.push(numberValidator(rule.errorMessage));
		} else if (key === 'range') {
			validators.push(rangeValidator(rule.parameters.min, rule.parameters.max, rule.errorMessage));
		} else if (key === 'regex') {
			validators.push(regexValidator(new RegExp(rule.parameters.pattern), rule.errorMessage));
		} else if (key === 'skipsanitization') {
			skipSanitization = true;
		} else {
			throw new Error(`Encountered an unimplemented validation rule on metadata for ${metadata.propertyName} (rule name = "${key}").`);
		}
	}

	if (skipSanitization === false) {
		const errorMessage = 'For your security, please only enter your credit card details into credit card fields.';
		validators.push(noCreditCardDetailsValidator(errorMessage));
	}

	return combineValidators<any>(...validators);
}

class FieldValidationSuccess {
	public isValid = true;
	constructor(public translationParams?: IParams) {}
}

export const fieldValidationSuccess = new FieldValidationSuccess();

export class FieldValidationFailure {
	public isValid = false;

	constructor(
		public ruleType: RuleType,
		public validationError: string,
		public translationParams?: IParams
	) {}
}

export type FieldValidationResult = FieldValidationFailure | FieldValidationSuccess;
