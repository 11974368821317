import * as React from 'react';
import { SkeletonRow, SkeletonRowProps, skeletonRowMinWidth } from './skeleton-row';
import * as styles from './skeleton.less';

export const skeletonDataType = 'skeletonLoaderElement';

export interface SkeletonProps {
	rows?: number | SkeletonRowProps[];
}

export const Skeleton = (props: SkeletonProps) => {
	const { rows = 1 } = props;
	const rowOptions: SkeletonRowProps[] = typeof rows === 'number' ? [...Array(rows)].map(() => ({ width: skeletonRowMinWidth})) : rows;

	return (
		<span className={styles.skeleton} data-element-type={skeletonDataType}>
			{rowOptions.map((option, index) => {
				const key = `row-${index}`;
				return <SkeletonRow key={key} {...option} data-pp-at-target={key} />;
			})}
		</span>
	);
};
