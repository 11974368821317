import * as React from 'react';
import * as styles from './form-control-validation-error.less';
import { classNames } from '../../../Shared/utils/classnames';
import { IParams, Text } from '../../components/text';

export interface IFieldValidationMessageProps {
	errorMessage?: string;
	translationParams?: IParams;
	id?: string;
}

export class FieldValidationMessage extends React.Component<IFieldValidationMessageProps, {}> {
	render() {
		const { errorMessage, id, translationParams } = this.props;
		const classes = classNames('validation-error-message', {
			[styles.validationErrorMessage]: !!errorMessage,
		});
		const ariaLabel = errorMessage ? `There is a problem with a field: ${errorMessage}` : undefined;
		return (
			<span
				id={id}
				role="alert"
				aria-atomic="true"
				className={classes}
				aria-label={ariaLabel}
			>
				{!!errorMessage ? <Text params={translationParams}>{errorMessage}</Text> :  errorMessage }
			</span>
		);
	}
}
