import * as React from 'react';
import { MerchantLogo } from '../../WebGiving/components/header/merchant-logo';
import { AppConnectMerchantViewModel } from '../app-connect-generated';
import { classNames } from '../../Shared/utils/classnames';
import * as headerStyles from '../../WebGiving/components/header/header.less';
import * as mainStyles from '../../WebGiving/main/webgiving-main.less';

export class AppConnectMainHeader extends React.Component<{ merchant: AppConnectMerchantViewModel, logoUrl: string }, {}> {
	render() {
		const { merchant, logoUrl } = this.props;
		return (
			<header className={classNames(mainStyles.header, 'brand-secondary-bg')}>
				<div className={`container ${headerStyles.headerContainer}`}>
					<div className={headerStyles.merchantHeader}>
						<div className={headerStyles.merchantDetailsWrapper}>
							<MerchantLogo logoUrl={logoUrl} tradingName={merchant.TradingName} websiteUrl={merchant.WebsiteUrl} logoClass={headerStyles.merchantLogo} />
							<div className={headerStyles.merchantName}>{merchant.TradingName}</div>
						</div>
					</div>
				</div>
			</header>
		);
	}
}
