import * as React from 'react';
import { classNames } from '../../../Shared/utils/classnames';

import { button, hiddenText, ghost, loadingSpinner, blocked, text } from './button.less';

export enum ButtonStyle {
	Primary,
	Ghost,
	Custom,
	Text,
}

export interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	acceptanceTestTargetId: string;
	/**
	 * Defines the button type: 'submit' or 'button'
	 * Default button type is 'button'
	 */
	buttonStyle?: ButtonStyle;
	submit?: boolean;
	isLoading?: boolean;
	isBlocked?: boolean;
}

export function getBrandingClass(buttonStyle: ButtonStyle) {
	if (!NewFeatures.SplitGiving_WebGivingUX) {
		return buttonStyle === ButtonStyle.Primary
			? 'brand-primary-bg brand-primary-hover'
			: `${ghost} brand-primary-border brand-primary-hover brand-primary-border-hover brand-primary-text`;
	}
	switch(buttonStyle) {
		case ButtonStyle.Primary:
			return 'brand-primary-bg brand-primary-hover';
		case ButtonStyle.Text:
			return `${text} brand-primary-text brand-primary-hover`
		case ButtonStyle.Ghost:
		default:
			return `${ghost} brand-primary-border brand-primary-hover brand-primary-border-hover brand-primary-text`;
	}
}

export function getBrandedButtonClass(buttonStyle: ButtonStyle) {
	return classNames(button, getBrandingClass(buttonStyle));
}

export const Button: React.StatelessComponent<IButtonProps> = (props) => {
	const { submit, isLoading, children, buttonStyle = ButtonStyle.Primary, className = '', acceptanceTestTargetId, isBlocked, ...buttonProps } = props;
	const brandingClass = getBrandingClass(buttonStyle);

	return (
		<button
			className={classNames(className, button, isBlocked ? blocked : brandingClass )}
			type={submit ? 'submit' : 'button'}
			data-pp-at-target={acceptanceTestTargetId}
			disabled={isLoading || isBlocked}
			{...buttonProps}
		>
			<span className={isLoading ? hiddenText: ''}>{children}</span>
			{isLoading && <div className={loadingSpinner} />}
		</button>
	);
};

export const CustomButton: React.StatelessComponent<IButtonProps> = (props) => {
	const {
		submit,
		isLoading,
		children,
		buttonStyle = ButtonStyle.Primary,
		className = '',
		acceptanceTestTargetId,
		isBlocked,
		...buttonProps
	} = props;
	const brandingClass = buttonStyle === ButtonStyle.Custom ? '' : getBrandingClass(buttonStyle);

	return (
		<button
			className={classNames(className, button, isBlocked ? blocked : brandingClass)}
			type={submit ? 'submit' : 'button'}
			data-pp-at-target={acceptanceTestTargetId}
			disabled={isLoading || isBlocked}
			{...buttonProps}
		>
			<span className={isLoading ? hiddenText : ''}>{children}</span>
			{isLoading && <div className={loadingSpinner} />}
		</button>
	);
};
