import moment from 'moment';
import { FloatMath } from './floatmath';

export const DEFAULT_DATE_FORMAT = 'MMM D YYYY';

export module Formatter {

	export function formatNumberForDisplay(number: number | null, precision = 2): string {
		if (number === null || isNaN(parseFloat(`${number}`))) {
			return '';
		}

		const fixedNumber = FloatMath.numberToFixedString(FloatMath.round(number, precision), precision);
		if (precision === 0) {
			return fixedNumber.replace(/(\d)(?=(\d\d\d)+(?!\d)$)/g, '$1,');
		}
		return fixedNumber.replace(/(\d)(?=(\d\d\d)+(?!\d)\.)/g, '$1,');
	}

	export function formatDate(date: Date, dateFormat: string = DEFAULT_DATE_FORMAT) {
		if (!date) {
			return '';
		}

		return moment(date).format(dateFormat);
	}
}
