import * as React from 'react';
import { InformationIcon } from '../../components/custom-svg/custom-svg';
import { SvgIcon } from '../../components/svg-icon';

import { content, iconLink, link, linkInheritFontSize, disabled } from './link.less';
import edit from '../../assets/svg/icon-edit.svg';
import chevronLeft from '../../assets/svg/icon-chevron-left.svg';
import chevronRight from '../../assets/svg/icon-chevron-right.svg';
import externalLink from '../../assets/svg/icon-external-link.svg';
import { classNames } from '../../../Shared/utils/classnames';

export interface LinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
	acceptanceTestTargetId: string;
	disabled?: boolean;
	branded: boolean;
	openInNewTab?: boolean;
	contentProps?: React.HTMLProps<HTMLSpanElement>;
}

export const RightChevronLink: React.StatelessComponent<LinkProps> = (props) => {
	return (<LinkWithIcon {...props}><LinkWithIconContent {...props.contentProps}>{props.children}</LinkWithIconContent><SvgIcon svg={chevronRight} /></LinkWithIcon>);
};

export const LeftChevronLink: React.StatelessComponent<LinkProps> = (props) => {
	return (<LinkWithIcon {...props}><SvgIcon svg={chevronLeft} /><LinkWithIconContent {...props.contentProps}>{props.children}</LinkWithIconContent></LinkWithIcon>);
};

export const InfoLink: React.StatelessComponent<LinkProps> = (props) => {
	return (
		<LinkWithIcon {...props} >
			<InformationIcon />
			<LinkWithIconContent {...props.contentProps}>{props.children}</LinkWithIconContent>
		</LinkWithIcon>
	);
};

export const ExternalLink: React.StatelessComponent<LinkProps> = (props) => {
	return (
		<LinkWithIcon {...props} openInNewTab={true}>
			<LinkWithIconContent {...props.contentProps}>{props.children}</LinkWithIconContent>
			<SvgIcon svg={externalLink} />
		</LinkWithIcon>);
};

export const Link: React.StatelessComponent<LinkProps & { inheritFontSize?: boolean }> = (props) => {
	const { className = '', inheritFontSize, ...rest } = props;
	return (<LinkInner {...rest} className={classNames(className, link, { [linkInheritFontSize]: inheritFontSize })} />);
};

export const EditLink: React.StatelessComponent<LinkProps> = (props) => {
	return (
		<LinkWithIcon {...props}>
			<SvgIcon svg={edit} />
			<LinkWithIconContent {...props.contentProps}>{props.children}</LinkWithIconContent>
		</LinkWithIcon>);
};

export const LinkWithIcon: React.StatelessComponent<LinkProps> = (props) => {
	const { className = '', ...rest } = props;
	return (
		<LinkInner {...rest} className={classNames(className, iconLink)}>
			{props.children}
		</LinkInner>
	);
};

export const LinkWithIconContent: React.StatelessComponent<React.HTMLProps<HTMLSpanElement>> = (props) => {
	const { children, className, ...rest } = props;
	return (
		<span className={classNames(className, content)} {...rest}>
			{children}
		</span>
	);
};

export const DisabledLink: React.StatelessComponent<React.HTMLProps<HTMLSpanElement>> = (props) => {
	const { children, className, ...rest } = props;
	return (
		<span className={classNames(className, link, disabled)} {...rest}>
			{children}
		</span>
	);
};

export const DisabledRightChevronLink: React.StatelessComponent<React.HTMLProps<HTMLSpanElement> & { branded: boolean }> = (props) => {
	const { children, className, branded, ...rest } = props;
	return (
		<span className={classNames(className, iconLink, disabled, { 'brand-link': branded })} {...rest}>
			<span className={content}>{children}</span>
			<SvgIcon svg={chevronRight} />
		</span>
	);
};

class LinkInner extends React.Component<LinkProps>  {
	render() {
		let { acceptanceTestTargetId, openInNewTab, className, branded, ...rest } = this.props;
		let { href } = this.props;
		href = href || '#';

		if (openInNewTab) {
			rest = { ...rest, target: '_blank', rel: 'noopener noreferrer' };
		}

		return (
			<a {...rest}
				onClick={this.handleClick}
				href={href}
				className={classNames(className, { 'brand-link': branded })}
				data-pp-at-target={acceptanceTestTargetId}>
				{this.props.children}
			</a>);
	}

	private handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
		if (!this.props.href || this.props.href === '#' || this.props.disabled) {
			event.stopPropagation();
			event.preventDefault();
		}
		if (this.props.onClick && !this.props.disabled) {
			this.props.onClick(event);
		}
	}
}
