import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { configure } from 'mobx';
import { AjaxUtils } from '../Shared/utils/ajax-utils';
import { AppConnectViewModel } from './app-connect-generated';
import { AppConnectMainViewModel } from './main/app-connect-main-view-model';
import { AppConnectMain } from './main/app-connect-main';

configure({ enforceActions: true });

export function init(model: string) {
	initApp(parseModelJSON(model));
}

function parseModelJSON(model: string): AppConnectViewModel {
	return JSON.parse(model, AjaxUtils.reviveDate);
}

function initApp(model: AppConnectViewModel) {
	const vm = new AppConnectMainViewModel(model);
	ReactDOM.render(React.createElement(AppConnectMain, { vm }), document.getElementById('react-container'));
}
