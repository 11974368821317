export function isCharDigit(a: string) {
	const charCode = a.charCodeAt(0);
	return charCode >= 48 && charCode <= 57; // 0-9
}

const charCodeForPlus = '+'.charCodeAt(0);

export function isPhoneNumberChar(a: string) {
	const charCode = a.charCodeAt(0);
	return charCode >= 48 && charCode <= 57 || charCode === charCodeForPlus; // 0-9 or +
}
