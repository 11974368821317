export class Subject<T> {
	private callbacks: ((t: T) => void)[] = [];

	/**
	 * Returns a function that can be invoked to unregister the callback
	 * @param callback
	 */
	register(callback: (t: T) => void): () => void {
		this.callbacks.push(callback);
		return () => {
			var index = this.callbacks.indexOf(callback);
			if (index > -1) {
				this.callbacks.splice(index, 1);
			}
		};
	}

	raise(t: T) {
		this.callbacks.forEach(x => x(t));
	}
}
