export class TranslationLogging {
    // Strictly set to spanish for now
	es: { [key: string]: string } = {};
	pageTitle: string;

	constructor() {
		this.es = {};
		this.pageTitle = "";
	}
	saveESKey(key: string): void {
        if (!!key) {
			// PageTitle added for context
            this.es = this.sort({ ...this.es, [`${this.pageTitle}[${key}]`]: key });
        }
	}
	setPageTitle(page: string): void {
		this.pageTitle = page;
	}
	clearESKeys(): void {
		this.es = {};
	}
	getESKeys(): string[] {
		return Object.keys(this.es);
	}
	hasESKeys(): boolean {
		return !!Object.keys(this.es).length;
	}
	private sort(obj: { [key: string]: any }): any {
		if (typeof obj !== "object" || obj instanceof Array) return obj;
		const keys = Object.keys(obj).sort();
		const ordered: { [key: string]: any } = {};

		for (const index in keys) {
			ordered[keys[index]] = this.sort(obj[keys[index]]);
		}

		return ordered;
	}
}
