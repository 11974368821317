import { isCharDigit } from '../../Shared/utils/string-functions';
import { Formatter, stripNonNumber, centsToDollarFormat } from '../../Shared/utils/formatter';

const precision = 2;
const decorators = /[.,]/;

// TextMaskState
export type TextMaskState = {
	value: string;
	caretPosition: number
};

export type TextMaskOldState = {
	value: string;
	selectionStart: number;
	selectionEnd: number;
};

export type TextMaskFnResult = {
	char: string;

	// by default calculated from diff between the original char and the resulting char
	// can be overriden for more granular control
	caretPositionDelta?: number;
	unshift?: string
};

export type TextMaskFn =
	(char: string, oldState: TextMaskOldState | null, nextChars: string[], previousChars: string[]) => TextMaskFnResult;

export type TextMask = RegExp | string | TextMaskFn;

export function testRegExp(regExp: RegExp, char: string): TextMaskFnResult {
	if (regExp.test(char)) {
		return { char };
	}

	return { char: '' };
}

export function testPlaceholder(placeholder: string, char: string, index: number, caretPosition: number): TextMaskFnResult {
	if (char === placeholder) {
		return {
			char,
			caretPositionDelta: index === caretPosition ? 1 : 0,
		};
	} else {
		return {
			char: placeholder,
			caretPositionDelta: index <= caretPosition ? 1 : 0,
			unshift: char,
		};
	}
}

/**
 * It currently works perfectly for credit card numbers and expiry dates
 * Please use with caution for anything else
 */
export function processTextMask(mask: TextMask[], oldState: TextMaskOldState | null, newState: TextMaskState): TextMaskState {
	const { value, caretPosition } = newState;

	let newCaretPosition = caretPosition;
	let index = 0;
	const chars: string[] = [];

	const charsToCheck = value.split('');

	while (true) {
		const charInfo = mask[index];
		if (!charInfo) {
			// exceeds the mask length
			break;
		}

		const char = charsToCheck.shift();
		if (typeof char !== 'string') {
			// no more charsToCheck
			// add placeholder if possible and if it's at the same position as the caret
			if (typeof charInfo === 'string' && newCaretPosition === index) {
				chars.push(charInfo);

				if (index <= newCaretPosition) {
					newCaretPosition++;
				}
				index++;
				continue;
			} else {
				break;
			}
		}

		let result: TextMaskFnResult;

		if (typeof charInfo === 'function') {
			result = charInfo(char, oldState, charsToCheck.slice(), chars.slice());
		} else if (charInfo instanceof RegExp) {
			result = testRegExp(charInfo, char);
		} else {
			result = testPlaceholder(charInfo, char, index, newCaretPosition);
		}

		if (result !== null) {
			if (typeof result.caretPositionDelta === 'number') {
				newCaretPosition += result.caretPositionDelta;
			} else if (index < newCaretPosition) {
				newCaretPosition += result.char.length - char.length;
			}

			if (result.char !== '') {
				chars.push(result.char);
				index = index + result.char.length - char.length + 1;
			}

			if (result.unshift) {
				charsToCheck.unshift(result.unshift);
			}
		}
	}

	if (oldState && oldState.value.length > value.length && (oldState.selectionStart >= caretPosition && oldState.selectionEnd !== caretPosition)) {
		// remove placeholders at caret position
		let removingIndex = newCaretPosition - 1;

		while (true) {
			const charInfo = mask[removingIndex];

			if (!charInfo || chars[removingIndex] !== charInfo) {
				break;
			}

			if (removingIndex + 1 === chars.length) {
				chars.splice(removingIndex, 1);
			}

			if (removingIndex < newCaretPosition) {
				newCaretPosition--;
			}

			removingIndex--;
		}
	}

	return {
		value: chars.join(''),
		caretPosition: newCaretPosition,
	};
}

export function getMaskFromGroups(groups: number[]): (RegExp | string)[] {
	return groups.reduce((acc, x, index) => {
		const isLastGroup = index === groups.length - 1;
		for (let i = 0; i < x; i++) {
			acc.push(/[0-9]/);
		}

		if (!isLastGroup) {
			acc.push(' ');
		}

		return acc;
	}, [] as (RegExp | string)[]);
}

function checkSomethingRemoved(oldState: TextMaskOldState, newState: TextMaskState): IFormattedTextRemovalResult | null {
	const { value: newValue, caretPosition: newCaretPosition } = newState;
	const { value: oldValue, selectionStart: oldSelectionStart, selectionEnd: oldSelectionEnd } = oldState;

	//Check if something was removed
	if (oldSelectionEnd === oldSelectionStart && oldValue.length > newValue.length) {
		const leftOldChar = oldValue[oldSelectionEnd - 1];
		const rightOldChar = oldValue[oldSelectionEnd];
		const leftNewChar = newValue[newCaretPosition - 1];
		const rightNewChar = newValue[newCaretPosition];

		const data: IFormattedTextRemovalInput = {
			leftOfCaret: oldValue.substring(0, oldSelectionStart),
			rightOfCaret: oldValue.substring(oldSelectionStart),
		};

		//check if left or right is a decorator
		if (decorators.test(leftOldChar) && !decorators.test(leftNewChar)) {
			return handleBackSpaceOnFormattedText(data);
		} else if (decorators.test(rightOldChar) && !decorators.test(rightNewChar)) {
			return handleDeleteOnFormattedText(data);
		}
	}

	return null;
}

export function handleDecoratedText(oldState: TextMaskOldState | null, newState: TextMaskState): TextMaskState {
	const { value: newValue, caretPosition: newCaretPosition } = newState;

	const newDollarAmount = centsToDollarFormat(stripNonNumber(newValue));

	let result: IFormattedTextRemovalResult | null = null;
	if (oldState) {
		result = checkSomethingRemoved(oldState, newState);
	}

	if (result && result.handled) {
		const amount = centsToDollarFormat(stripNonNumber(result.malformedNewText));
		const desiredDisplayValue = Formatter.formatNumberForDisplay(amount, 2);
		return {
			value: desiredDisplayValue,
			caretPosition: desiredDisplayValue.length - result.newCaretPositionFromRight,
		};
	}

	let fromRight = newValue.length === 1 ? '.##'.length : newValue.length - newCaretPosition;
	const desiredDisplayValue = Formatter.formatNumberForDisplay(newDollarAmount, precision);

	if (newValue[0] === ',') {
		fromRight--;
	}

	return {
		value: desiredDisplayValue,
		caretPosition: desiredDisplayValue.length - fromRight,
	};
}


export interface IFormattedTextRemovalInput {
	leftOfCaret: string;
	rightOfCaret: string;
}

export interface IFormattedTextRemovalResult {
	handled: boolean;
	malformedNewText: string;
	newCaretPositionFromRight: number;
}


export function handleBackSpaceOnFormattedText(data: IFormattedTextRemovalInput): IFormattedTextRemovalResult {
	let handled: boolean = false;
	let malformedNewText: string = data.leftOfCaret + data.rightOfCaret;
	const newCaretPositionFromRight: number = data.rightOfCaret.length;
	if (data.leftOfCaret.length) {
		const c = data.leftOfCaret.charAt(data.leftOfCaret.length - 1);
		if (!isCharDigit(c)) {
			handled = true;
			malformedNewText = data.leftOfCaret.slice(0, -2) + data.rightOfCaret;
		}
	}

	return { handled, malformedNewText, newCaretPositionFromRight };
}

export function handleDeleteOnFormattedText(data: IFormattedTextRemovalInput): IFormattedTextRemovalResult {
	let handled: boolean = false;
	let malformedNewText: string = data.leftOfCaret + data.rightOfCaret;
	const newCaretPositionFromRight: number = data.rightOfCaret.length - 2;
	if (data.rightOfCaret.length) {
		const c = data.rightOfCaret.charAt(0);
		if (!isCharDigit(c)) {
			handled = true;
			malformedNewText = data.leftOfCaret + data.rightOfCaret.slice(2);
		}
	}

	return { handled, malformedNewText, newCaretPositionFromRight };
}
