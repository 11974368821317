import * as React from 'react';

interface ILogoProps {
	logoUrl: string;
	tradingName: string;
	websiteUrl: string | null;
	logoClass: string;
	alt?: string | null;
}

export function MerchantLogo({ logoUrl, tradingName, websiteUrl, logoClass, alt }: ILogoProps) {
	const altText = alt || '';
	const logo = <img data-pp-at-target="Merchant logo" className={logoClass} src={logoUrl} alt={altText} />;
	if (websiteUrl) {
		return <a href={websiteUrl}>{logo}</a>;
	}
	return logo;
}
