let features: { [key: string]: boolean };

declare const window: any;
declare const UNIT_TEST: boolean;

export function getFeatures() {
	if (!features) {
		window.pushpay = window.pushpay || {};
		features = window.pushpay.features = window.pushpay.features || {};

		freezeFeaturesToAvoidMutationAtRuntime(features);
	}

	return features;
}

function freezeFeaturesToAvoidMutationAtRuntime(features: object) {
	Object.freeze(features);
}

const reportedUndefinedFeatures: string[] = [];

export function getFeatureValue(featureName: string): boolean {
	/**
	 * There can be 4 cases reading feature flags
	 * 1. You are running in a unit test lets return true as unit test feature config is pretty much always true
	 * 2. Value is passed and it's declared in the generated file
	 * 	all good, everything is consitent
	 * 3. Value is passed but it's not declared in the generated file
	 * 	Nothing in the bundle uses it, it's safe to ignore.
	 * 4. Value is not passed but it's declared in the generated file
	 * 	The worst case, the flag is used in UI but server does not pass the value.
	 * 	There's no good default for a feature flag, but we want to make safe assumption and provide some value
	 * 	if it happened in Prod. Returning false seems like a good idea - falling back to the state that was
	 * 	working at some point it the past
	 * 	However, we still want to fail loudly in dev
	 */
	if (UNIT_TEST) {
		return true;
	}

	const value = getFeatures()[featureName];
	if (typeof value !== 'boolean') {
		const error = new Error(`Feature ${featureName} value is not defined`);
		if (DEBUG) {
			throw error;
		} else {
			if (reportedUndefinedFeatures.indexOf(featureName) === -1) {
				reportedUndefinedFeatures.push(featureName);
				window.reportUnhandledRejection(error, { featureName });
			}
			return false;
		}
	}
	return value;
}

export default getFeatureValue;
