let submitActivationStatus = false;

export function activateSubmit() {
	submitActivationStatus = true;
}

export function deactivateSubmit() {
	submitActivationStatus = false;
}

export function submitIsActivated() {
	return submitActivationStatus;
}
