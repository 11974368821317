import { fixedHeaderPlusLabelHeight } from '../main/webgiving-main.less';

let nextFrame: number | undefined;

export function scrollErrorIntoViewOnNextFrame() {
	if (nextFrame) {
		return;
	}

	nextFrame = requestAnimationFrame(() => {
		nextFrame = undefined;

		const errorMessage = document.querySelector('[data-alert]');
		if (errorMessage) {
			scrollToElement(errorMessage);
			return;
		}

		const firstInvalidField = document.querySelector('[data-field-invalid]');
		if (firstInvalidField) {
			(firstInvalidField as HTMLInputElement | HTMLSelectElement).focus();
			scrollToElement(firstInvalidField);
		}
	});
}

function scrollToElement(element: Element) {
	if (isElementFullyVisible(element)) {
		// no need to scroll if the element is fully visible
		return;
	}

	element.scrollIntoView(true);
	const elementTop = element.getBoundingClientRect().top;
	const fixedHeaderPlusLabelHeightAsNumber = parseInt(fixedHeaderPlusLabelHeight);
	if (elementTop < fixedHeaderPlusLabelHeightAsNumber) {
		window.scrollBy(0, -fixedHeaderPlusLabelHeightAsNumber);
	}
}

function isElementFullyVisible(element: Element): boolean {
	const fixedHeaderPlusLabelHeightAsNumber = parseInt(fixedHeaderPlusLabelHeight);

	const elementBounds = element.getBoundingClientRect();
	const viewportHeight = document.documentElement.clientHeight;

	return elementBounds.top > fixedHeaderPlusLabelHeightAsNumber && elementBounds.bottom <= viewportHeight;
}
