import { AjaxUtils } from './ajax-utils';
import { CancellablePromise, ICancellablePromise } from './cancellable-promise';
import { addQueryStringParameters } from '../../Shared/utils/url-utils';

export class PostError {
	userError: string | null;
	shouldReloadPage: boolean;
	validationErrors: { [s: string]: string };
	timedout: boolean;
	conflict: boolean;
	response: string;
	updatedRecordForConflict: any;
	isPostError: true = true;
	//A JS Error that represents this
	asJsError: Error;

	constructor(public client: XMLHttpRequest, url: string) {
		this.asJsError = new Error(`PostError:${client.status} Url:${url || 'unknown'}`);

		this.response = client.response;

		if (navigator.onLine === false) {
			this.userError = 'Sorry, something went wrong. There was a problem communicating with Pushpay. Please check your internet connection.';
			return;
		}

		switch (client.status) {
			case 205: //Reset Content = reload page
				this.shouldReloadPage = true;
				this.userError = client.getResponseHeader('X-Reset-Because');
				break;

			case 403: //verboten
			case 401: //Unauthorised = likely CSRF = reload page
				this.shouldReloadPage = true;
				this.userError = 'Session has expired, please log in again.';
				break;

			case 409: // conflict: someone else edited it
				//todo:
				this.conflict = true;
				if (client.response) {
					this.updatedRecordForConflict = JSON.parse(client.response, AjaxUtils.reviveDate);
				}
				this.userError = 'Your changes could not be saved as another user was editing this record at the same time. Please reload the page and try again.';
				break;

			case 422: //Unprocessable entity = validation errors
				this.validationErrors = JSON.parse(client.response || null);
				break;

			case 0: //timeout
				this.timedout = true;
				this.userError = `There was a problem communicating with Pushpay and we're currently looking into it. Please try again soon.`;
				break;

			default:
				this.userError = `Our system encountered an unexpected error and we're currently looking into it. Please try again soon.`;
				break;
		}
	}
}

export interface PostOptions {
	timeout?: number;
	baseUrl?: string;
	antiForgeryToken?: string;
	customHeaders?: { header: string, value: string }[];
	queryStringParameters?: { [key: string]: string };
}

export function post<T>(url: string, data: any, options?: PostOptions): ICancellablePromise<T> {
	const antiForgeryTokenElement = document.querySelector('[name="__RequestVerificationToken"]') as HTMLInputElement;
	const opts = options || {};

	opts.timeout = opts.timeout || 20000;

	if (opts.queryStringParameters) {
		url = addQueryStringParameters(url, opts.queryStringParameters);
	}

	return new CancellablePromise<T>((resolve, reject, onCancel) => {
		var client = new XMLHttpRequest();
		client.onload = () => {
			if (client.status === 200) {
				resolve(JSON.parse(client.response || null, AjaxUtils.reviveDate));
			} else if (client.status === 204) {
				resolve();
			} else {
				reject(new PostError(client, url));
			}
		};

		var requestUrl = AjaxUtils.combineWithBasePath(url, opts.baseUrl);

		client.open('POST', requestUrl);

		client.onerror = () => reject(new PostError(client, requestUrl));
		client.ontimeout = () => reject(new PostError(client, requestUrl));

		onCancel(() => client.abort());

		client.timeout = opts.timeout || 0; //must be set after open in some browsers
		client.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
		client.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
		if (opts.customHeaders instanceof Array) {
			opts.customHeaders.forEach(customHeader => {
				client.setRequestHeader(customHeader.header, customHeader.value);
			});
		}

		data = { '__RequestVerificationToken': opts.antiForgeryToken || (antiForgeryTokenElement && antiForgeryTokenElement.value || ''), ...data };

		client.send(AjaxUtils.dictionaryToUrlEncodedFormString(data));
	});
}
