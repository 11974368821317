export class LocalStorageHelper {

	private static localStorage: Storage;
	private static isCreated = false;

	static getLocalStorage(): Storage {
		if (!this.isCreated) {
			this.isCreated = true;
			this.localStorage = this.createLocalStorage();
		}

		return this.localStorage;
	}

	private static createLocalStorage(): Storage {
		try {
			if (typeof window === 'undefined') {
				return new InMemoryStorage(); // Should always have window, except for unit tests
			}
			const storage = window.localStorage;
			storage.setItem('works', 'true');
			storage.removeItem('works');
			return storage;
		} catch (e) {
			console.warn('LocalStorage not available', e);
			return new InMemoryStorage();
		}
	}
}

export class InMemoryStorage implements Storage {
	[key: string]: any;
	get length(): number {
		return this.getKeys().length;
	}
	getItem(key: string) {
		return this[key] || null;
	}
	key(index: number): string {
		return this.getKeys()[index];
	}
	setItem(key: string, data: string): void {
		this[key] = data;
	}
	removeItem(key: string): void {
		if (this.containsKey(key)) {
			delete this[key];
		}
	}
	clear(): void {
		// ReSharper disable once MissingHasOwnPropertyInForeach
		this.getKeys().forEach(key => {
			delete this[key];
		});
	}
	private containsKey(key: string): boolean {
		return this.hasOwnProperty(key);
	}
	private getKeys(): string[] {
		return Object.keys(this).filter(x => this.containsKey(x));
	}
}
