import { observable, action, computed } from 'mobx';
import { FieldValidationResult, FieldValidationFailure, ValidatorFunc, RuleType, alwaysValid, combineValidators } from '../validation/validation-rules';
import { submitIsActivated} from '../utils/submit-activator';
import { Subject } from '../../Shared/utils/subject';
import { scrollErrorIntoViewOnNextFrame } from '../utils/error-focuser';
import { IParams } from './text';
export type FormatterFunc<T> = (value: T) => T;

export class FormField<T> {
	@observable
	value: T;

	@observable
	isLocked: boolean;

	@observable
	validationError?: string;

	@observable
	submitted = false;

	updateSubject = new Subject<FormField<T>>();

	private hasServerError = false;

	private translationParams: IParams | undefined

	@computed
	get hasValidationError() {
		return !!this.validationError;
	}

	static Unvalidated<T>(defaultValue: T): FormField<T> {
		return new FormField<T>(defaultValue, alwaysValid());
	}

	addCustomValidator(validator: ValidatorFunc<T>) {
		this.validator = combineValidators(this.validator, validator);
	}

	hasValidationRule = (rule: RuleType) => !!(this.validator.ruleType & rule);

	@action.bound
	validateOnSubmit() : FieldValidationResult {
		this.submitted = true;
		return this.validate();
	}

	@action.bound
	revalidate() {
		if (submitIsActivated()) {
			return;
		}
		if (!this.submitted) {
			return;
		}
		this.validate();
	}

	@action.bound
	validateEveryTimeCauseImASpecialCase()	{
		return this.validate();
	}

	constructor(defaultValue: T, private validator: ValidatorFunc<T>, translationParams?: IParams | undefined) {
		this.value = defaultValue;
		this.translationParams = translationParams;
	}

	@action.bound
	lockAmount() {
		this.isLocked = true;
	}

	@action.bound
	unLockAmount() {
		this.isLocked = false;
	}

	@action.bound
	updateValue(value: T) {
		if (value !== this.value) {
			this.hasServerError = false;
		}
		this.value = value;
		this.updateSubject.raise(this);
	}

	@action.bound
	setServerSideValidationError(validationError: string) {
		this.validationError = validationError;
		this.hasServerError = true;

		scrollErrorIntoViewOnNextFrame();
	}

	@action.bound
	resetField() {
		this.validationError = undefined;
		this.submitted = false;
		this.hasServerError = false;
	}

	@action.bound
	clearServerError() {
		this.hasServerError = false;
	}

	@action.bound
	getTranslationParams() {
		return this.translationParams;
	}

	@action.bound
	private validate() : FieldValidationResult {
		if (this.hasServerError && this.validationError) {
			return new FieldValidationFailure(RuleType.Server, this.validationError, this.getTranslationParams());
		}

		const validationResult = this.validator(this.value);
		if(validationResult instanceof FieldValidationFailure) {
			this.validationError = validationResult.validationError;
		} else {
			this.validationError = undefined;
		}
		validationResult.translationParams = this.getTranslationParams();
		return validationResult;
	}
}
