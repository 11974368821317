import * as React from "react";
import * as styles from "./skeleton.less";

export const skeletonRowMinWidth = 10;

export interface SkeletonRowProps {
	width: number;
}

export const SkeletonRow = (props: SkeletonRowProps) => {
	const { width = skeletonRowMinWidth } = props;
	return (
		<span
			className={styles.skeletonRow}
			style={{ width: `${width}ch` }}
		>
			<span className={styles.skeletonRowContent} />
		</span>
	);
};
