import React, { Suspense } from 'react';
import { useTranslation, useShouldTranslate } from '../../translation';
import { Skeleton, SkeletonProps } from '../../components/skeleton/skeleton';
import { interpolate } from '../../utils/interpolate-text';

export  interface IParams { [key: string]: any }
export  interface TextProps extends SkeletonProps {
	children: string | string[];
	params?: IParams;
}

const TranslatedText = ({ children, params }: TextProps) => {
	const { translate } = useTranslation();
	const key = Array.isArray(children) ? children.join('') : children;
	return <>{translate(key as any, params)}</>;
}

export const Text = ({ children, rows, params }: TextProps) => {
	const shouldTranslate = useShouldTranslate();
	if (shouldTranslate) {
		const width = typeof children === 'string' ? children.trim().length : children.reduce((a, b) => a + b).trim().length;

		return (
			<Suspense fallback={<Skeleton rows={rows ? rows : [{ width }]} />}>
				<TranslatedText params={params}>{children}</TranslatedText>
			</Suspense>
		);
	}

	if(params && Object.keys(params).length) {
		return <>{interpolate(Array.isArray(children) ? children.join('') : children, params)}</>
	}

	return <>{children}</>;
};

