import { Language, LanguageOptions } from '../webgiving-generated';
import { LocalStorageHelper } from '../../Shared/helpers/local-storage';
import { languageStorageKey } from '../translation';

export function getLanguageWithFallback(hasEntitlement?:boolean): Language {
	if (!hasEntitlement) {
		return LanguageOptions.en;
	}

	const storage = LocalStorageHelper.getLocalStorage();
	const lang = storage.getItem(languageStorageKey) || LanguageOptions.en.TwoLetterIsoCode;
	return LanguageOptions[lang];
}

export const isEmptyObject = (obj = {}) => 
	obj && Object.keys(obj).length === 0 && obj.constructor === Object;
