import * as React from 'react';
import Transition from 'react-transition-group/Transition';
import { observer } from 'mobx-react';

import { transitionDurationLongMs } from '../main/webgiving-main.less';

export const panelContentTransitionDuration: number = parseInt(transitionDurationLongMs, 10);

@observer
export class PanelContentTransition extends React.Component<{
	in: boolean,
	setContentHeight: (height: number) => void,
	className?: string;
}, {}> {
	contentNode: HTMLDivElement;

	render() {
		const { in: inProp, children, className } = this.props;
		const timeout = {
			enter: 0,
			exit: panelContentTransitionDuration,
		};
		const defaultStyle = {
			transitionProperty: 'transform, opacity',
			transitionDuration: `${panelContentTransitionDuration}ms`,
			transitionTimingFunction: 'cubic-bezier(0.86, 0, 0.07, 1)',
		};
		const enteringStyle = {
			opacity: 0,
			transform: 'translateX(100%)',
		};
		const transitionStyles: { [key: string]: {} } = {
			exited: enteringStyle,
			entering: enteringStyle,
			entered: {
				opacity: 1,
				transform: 'translateX(0%)',
			},
			exiting: {
				opacity: 0,
				transform: 'translateX(-100%)',
			},
		};

		return (
			<Transition in={inProp} timeout={timeout} onExit={this.setContentHeight} appear unmountOnExit>
				{(status: string) => React.cloneElement(children as React.ReactElement<any>, {
					className: className,
					style: Object.assign({}, defaultStyle, transitionStyles[status]) as Partial<CSSStyleDeclaration>,
					ref: this.saveContentNode,
					'data-pp-transition-state': status,
				})}
			</Transition>
		);
	}

	setContentHeight = () => {
		if (this.contentNode) {
			this.props.setContentHeight(this.contentNode.offsetHeight);
		}
	}

	private saveContentNode = (node: HTMLDivElement) => this.contentNode = node;
}
