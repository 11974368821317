import * as React from 'react';
import { observer } from 'mobx-react';
import { SvgIcon } from '../svg-icon';
import { Text } from '../text';
import errorIcon from '../../assets/svg/error.svg';

import { container, icon, iconMultiLine, content } from './alert.less';

@observer
export class Alert extends React.Component<{ content: React.ReactChild | null | undefined, id?: string, multipleLines?: boolean }> {

	render() {
		const { content: errorContent, id, multipleLines } = this.props;
		const isString = typeof errorContent === 'string';
		return errorContent ? (
			<div className={container} data-alert id={id}>
				<SvgIcon svg={errorIcon} className={multipleLines ? iconMultiLine : icon} />
				<div className={content}>
					{isString ? <Text>{errorContent as string}</Text> : errorContent}
				</div>
			</div>
		) : null;
	}
}
