import {action, observable} from 'mobx';

export enum ResponsiveVisibility {
	Visible,
	Hidden,
}

export namespace ResponsiveVisibility {
	export function isVisible(responsiveVisibility: ResponsiveVisibility) {
		return responsiveVisibility === ResponsiveVisibility.Visible;
	}
}

export enum ResponsiveBreakpoint {
	Xs = 1 << 0,
	Sm = 1 << 1,
	Md = 1 << 2,
	Lg = 1 << 3,
}

export namespace ResponsiveBreakpoint {
	export function isXs(responsiveBreakpoint: ResponsiveBreakpoint) {
		return responsiveBreakpoint & ResponsiveBreakpoint.Xs;
	}
	export function isSm(responsiveBreakpoint: ResponsiveBreakpoint) {
		return responsiveBreakpoint & ResponsiveBreakpoint.Sm;
	}
	export function isMd(responsiveBreakpoint: ResponsiveBreakpoint) {
		return responsiveBreakpoint & ResponsiveBreakpoint.Md;
	}
	export function isLg(responsiveBreakpoint: ResponsiveBreakpoint) {
		return responsiveBreakpoint & ResponsiveBreakpoint.Lg;
	}
}

type MediaMatcher = MediaQueryList | MediaQueryListEvent;

export class ResponsiveHelper {
	// Media queries should match bootstrap-variables.less
	readonly xsMediaQuery = '(max-width: 767px)';
	readonly smMediaQuery = '(min-width: 768px) and (max-width: 1024px)';
	readonly mdMediaQuery = '(min-width: 1025px) and (max-width: 1439px)';
	readonly lgMediaQuery = '(min-width: 1440px)';

	@observable isXs: boolean = false;
	@observable isSm: boolean = false;
	@observable isMd: boolean = false;
	@observable isLg: boolean = false;

	constructor() {
		// We might not have a window if we are inside a unit test
		if (typeof window === 'undefined' || !window.matchMedia) {
			return;
		}

		const xsMediaMatcher = window.matchMedia(this.xsMediaQuery);
		this.updateIsXs(xsMediaMatcher);
		xsMediaMatcher.addListener(this.updateIsXs);

		const smMediaMatcher = window.matchMedia(this.smMediaQuery);
		this.updateIsSm(smMediaMatcher);
		smMediaMatcher.addListener(this.updateIsSm);

		const mdMediaMatcher = window.matchMedia(this.mdMediaQuery);
		this.updateIsMd(mdMediaMatcher);
		mdMediaMatcher.addListener(this.updateIsMd);

		const lgMediaMatcher = window.matchMedia(this.lgMediaQuery);
		this.updateIsLg(lgMediaMatcher);
		lgMediaMatcher.addListener(this.updateIsLg);
	}

	isVisible = (visibility: ResponsiveVisibility, responsiveBreakpoints: ResponsiveBreakpoint) => {
		if(this.isXs && ResponsiveBreakpoint.isXs(responsiveBreakpoints)) {
			return ResponsiveVisibility.isVisible(visibility);
		}
		if(this.isSm && ResponsiveBreakpoint.isSm(responsiveBreakpoints)) {
			return ResponsiveVisibility.isVisible(visibility);
		}
		if(this.isMd && ResponsiveBreakpoint.isMd(responsiveBreakpoints)) {
			return ResponsiveVisibility.isVisible(visibility);
		}
		if(this.isLg && ResponsiveBreakpoint.isLg(responsiveBreakpoints)) {
			return ResponsiveVisibility.isVisible(visibility);
		}
		return !ResponsiveVisibility.isVisible(visibility);
	}

	@action
	private updateIsXs = ({ matches }: MediaMatcher) => {
		this.isXs = matches;
	}

	@action
	private updateIsSm = ({ matches }: MediaMatcher) => {
		this.isSm = matches;
	}

	@action
	private updateIsMd = ({ matches }: MediaMatcher) => {
		this.isMd = matches;
	}

	@action
	private updateIsLg = ({ matches }: MediaMatcher) => {
		this.isLg = matches;
	}
}

export const responsiveHelper = new ResponsiveHelper();
