let lastId = 0;

export function getUniqueElementId() {
	lastId++;
	return `${lastId}`;
}

export function getElementId(prefix: string, uniqueId: string) {
	return `${prefix}_${uniqueId}`;
}
