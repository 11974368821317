import * as React from 'react';
import { observer } from 'mobx-react';
import { getUniqueElementId } from '../../../Shared/utils/form-control-utils';
import { FormControlLabel } from '../../primitives/label';
import { FieldValidationMessage } from './form-control-validation-error';
import { IParams } from '../../components/text';

export interface IFormControlLabelledProps {
	label?: string;
	validationError?: string;
	className?: string;
	disableTranslation?: boolean;
	translationParams?: IParams;
	validationTranslationParams?: IParams;
}

@observer
export class FormControl extends React.Component<IFormControlLabelledProps, {}> {
	private formControlId = getUniqueElementId();

	render() {
		const { label, children, validationError, className, disableTranslation = false, translationParams, validationTranslationParams } = this.props;
		const validationErrorId = `${this.formControlId}-validation-error`;
		const control = React.cloneElement((React.Children.only(children) as React.ReactElement), {
			formControlId: this.formControlId,
			dataFieldInvalid: !!validationError,
			additionalClassNames: validationError ? 'form-control-invalid' : null,
			'aria-describedby': validationErrorId,
		});

		const containerClass = `form-control-container ${className ? className : ''}`;
		return (
			<div className={containerClass}>
				{label && <FormControlLabel disableTranslation={disableTranslation} translationParams={translationParams} className="form-control-label" formControlId={this.formControlId}>{label}</FormControlLabel>}
				{control}
				<FieldValidationMessage id={validationErrorId} errorMessage={validationError} translationParams={validationTranslationParams} />
			</div>
		);
	}
}