import { FormField } from '../components/form-field';
import { FieldValidationFailure, RuleType } from './validation-rules';

export interface IValidationSuccess {
	isValid: true;
}

export interface IValidationFailure {
	isValid: false;
	validationMessage: string;
	fieldErrorMessages: IFieldErrorMessage[];
}

export type ValidationState = IValidationFailure | IValidationSuccess;

export interface IFieldErrorMessage {
	fieldName: string;
	ruleType: RuleType;
	error: string;
}

const defaultErrorMessage = 'Please try again';

function getFieldErrorMessages(viewModel: any): IFieldErrorMessage[] {
	const fieldErrorMessages: IFieldErrorMessage[] = [];

	for (let key in viewModel) {
		const field: any = viewModel[key];
		if (field instanceof FormField) {
			const validationResult = field.validateOnSubmit();
			if (validationResult instanceof FieldValidationFailure) {
				fieldErrorMessages.push({ fieldName: key, ruleType: validationResult.ruleType, error: validationResult.validationError });
			}
		}
	}

	return fieldErrorMessages;
}

export function validateFields(viewModel: any, overallMessage?: string, additionalFields?: any): ValidationState {
	if (viewModel === null || viewModel === undefined || typeof viewModel !== 'object') {
		throw new Error('Cannot validate fields on viewModel: viewModel must be an object');
	}
	let fieldErrorMessages: IFieldErrorMessage[] = getFieldErrorMessages(viewModel);

	if (additionalFields) {
		fieldErrorMessages = fieldErrorMessages.concat(getFieldErrorMessages(additionalFields));
	}

	if (fieldErrorMessages.length === 0) {
		return { isValid: true };
	}

	return {
		isValid: false,
		fieldErrorMessages: fieldErrorMessages,
		validationMessage: getValidationMessage(overallMessage, fieldErrorMessages),
	};
}

function getValidationMessage(overallMessage: string | undefined, fieldErrorMessages: IFieldErrorMessage[]): string {
	if (overallMessage) {
		return overallMessage;
	}

	const nonEmptyErrorMessages = fieldErrorMessages.filter(x => x.error !== '');
	if(nonEmptyErrorMessages.length > 0) {
		return nonEmptyErrorMessages[0].error;
	}

	return defaultErrorMessage;
}
