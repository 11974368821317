import * as React from 'react';
import { isFunction } from '../../../Shared/utils/is-function';
import { SvgIcon } from '../../components/svg-icon';
import iconTickShadow from '../../assets/svg/icon-tick-shadow.svg';
import { FieldValidationMessage } from '../../components/form-control/form-control-validation-error';
import { IParams, Text } from '../../components/text';

import * as styles from './checkbox.less';
import { classNames } from '../../../Shared/utils/classnames';

export interface ICheckboxCommonProps {
	label: string;
	acceptanceTestTargetId: string;
	className?: string;
	formControlId?: string;
	disabled?: boolean;
	ariaDescribedBy?: string;
}

export interface ICheckboxPrimitiveProps {
	value: boolean;
	onChange: (value: boolean) => void;
	validationError?: string;
	translationParams?: IParams;
}

export type ICheckboxProps = ICheckboxCommonProps & ICheckboxPrimitiveProps;

export interface ICheckboxState {
	focusRingVisible: boolean;
}

export class Checkbox extends React.Component<ICheckboxProps, ICheckboxState> {

	constructor(props: ICheckboxProps) {
		super(props);
		this.state = {
			focusRingVisible: false,
		};
	}

	render() {
		const {
			value,
			label,
			formControlId,
			className,
			validationError,
			acceptanceTestTargetId,
			ariaDescribedBy,
			disabled,
			translationParams,
		} = this.props;

		const labelClasses = classNames(className, styles.container, {
			[styles.checked]: value,
			[styles.invalid]: !!validationError,
		});

		const focusRingClasses = classNames(styles.focusRing, {
			[styles.suppressed]: !this.state.focusRingVisible,
		});

		return (
			<label className={labelClasses}
				onBlur={this.hideFocusRing}
				data-pp-at-target={acceptanceTestTargetId ? `${acceptanceTestTargetId}_label`: undefined}
			>
				<input className={styles.input}
					id={formControlId}
					type="checkbox"
					data-pp-at-target={acceptanceTestTargetId}
					checked={value}
					onChange={this.handleChange}
					onKeyUp={this.showFocusRing}
					aria-label={label}
					disabled={disabled}
					data-field-invalid={validationError ? true : null}
					aria-describedby={`${formControlId}_validation ${ariaDescribedBy}`}
				/>
				<span className={focusRingClasses} aria-hidden>
					<span className={`${styles.contents} ${validationError ? styles.invalid : null}`}>
						<span><Text params={translationParams}>{label}</Text></span>
						<SvgIcon svg={iconTickShadow} className={`${styles.tick} ${! value && styles.suppressed}`} />
					</span>
				</span>
				{validationError &&
					<span className={styles.validationError}>
						<FieldValidationMessage id={`${formControlId}_validation`} errorMessage={validationError} />
					</span>
				}
			</label>
		);
	}

	private handleChange = (event: React.FormEvent<HTMLInputElement>) => {
		const { onChange } = this.props;
		if (isFunction(onChange)) {
			onChange(event.currentTarget.checked);
		}
	}

	private showFocusRing = () => {
		this.setState({focusRingVisible: true});
	}

	private hideFocusRing = () => {
		this.setState({focusRingVisible: false});
	}
}
