export function buildQueryString(parameters: { [key: string]: string }) {
	return Object.keys(parameters)
		.map(k => buildKeyValueQueryStringParameter(k, parameters[k]))
		.join('&');
}

export function addQueryStringParameters(url: string, parameters: { [key: string]: string }): string {
	return getUrlWithQueryStringPrefix(url) + buildQueryString(parameters);
}

export function addQueryStringParameter(url: string, key: string, value: string) {
	return getUrlWithQueryStringPrefix(url) + buildKeyValueQueryStringParameter(key, value);
}

function getUrlWithQueryStringPrefix(url: string): string {
	const urlHasQueryStringAlready = /\?/.test(url);
	return url + (urlHasQueryStringAlready ? '&' : '?');
}

function buildKeyValueQueryStringParameter(key: string, value: string) {
	return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
}
