import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import CSSTransition from 'react-transition-group/CSSTransition';
import * as notificationStyle from './notification.less';
import { SvgIcon } from '../svg-icon';
import { LocalStorageHelper } from '../../../LoggedInWeb/helpers/localstoragehelper';
import { IParams, Text } from '../../components/text';
import { classNames } from '../../../Shared/utils/classnames';
import { wait } from '@pushpay/utils';

import tickIcon from '../../assets/svg/icon-tick.svg';
import closeIcon from '../../assets/svg/icon-close.svg';
import alertIcon from '../../assets/svg/error.svg';
import { Link, LinkProps } from '../../primitives/link/link';
import { isFunction } from '../../../LoggedInWeb/utils/is-function';
import warningIcon from '../../assets/svg/warning.svg';

export class NotificationViewModel {

	@observable
	isActive: boolean;

	private timeoutHandle: number;

	constructor(public content: React.ReactChild, public autoDismissAfterSeconds: number, public hideIcon?: boolean, public storageKeyName?: string, public isError?: boolean, public isWarning?:boolean) {
		if (storageKeyName) {
			const state = LocalStorageHelper.getLocalStorage().getItem(storageKeyName);
			if (state) {
				this.isActive = false;
				return;
			}
		}

		this.isActive = true;
		this.timeoutHandle = window.setTimeout(this.dismiss, 1000 * autoDismissAfterSeconds);
	}

	@action
	dismiss = () => {
		clearTimeout(this.timeoutHandle);
		this.isActive = false;

		if (this.storageKeyName) {
			LocalStorageHelper.getLocalStorage().setItem(this.storageKeyName, 'true');
		}
	};
}

interface INotificationControllerProps {
	viewModel?: NotificationViewModel;
}

interface INotificationProps {
	content?: React.ReactChild;
	onDismiss?: () => void;
	hideIcon?: boolean;
	isError?: boolean;
	isWarning?: boolean;
}

@observer
export class NotificationController extends React.Component<INotificationControllerProps> {
	render() {
		const showNotification = this.props.viewModel && this.props.viewModel.isActive;
		const onDismiss = this.props.viewModel && this.props.viewModel.dismiss;
		const classNames = {
			appear: notificationStyle.notificationEnter,
			appearActive: notificationStyle.notificationEnterActive,
			enter: notificationStyle.notificationEnter,
			enterActive: notificationStyle.notificationEnterActive,
			exitActive: notificationStyle.notificationExitActive,
		};

		return (ReactDOM as any).createPortal(
			<CSSTransition
				in={showNotification}
				timeout={parseInt(notificationStyle.notificationTransitionDuration)}
				classNames={classNames}
				appear
				unmountOnExit
			>
				<Notification {...this.props.viewModel} onDismiss={onDismiss} />
			</CSSTransition>,
			document.body
		);
	}
}

export class Notification extends React.Component<INotificationProps> {
	render() {
		const { onDismiss, content, isError, isWarning } = this.props;
		const isString = typeof content === 'string';
		return (
			<div
				className={classNames(notificationStyle.notification, {
					[notificationStyle.notificationError]: isError,
					[notificationStyle.notificationWarning]: isWarning,
				})}
			>
				<div className={`${notificationStyle.notificationContainer}`} data-pp-at-target="Notification message">
					<div className={notificationStyle.notificationContent}>
						{this.getIcon()}
						<span className={notificationStyle.notificationText}>
							{isString ? <Text>{content as string}</Text> : content}
						</span>
					</div>
				</div>
				<button
					className={classNames(notificationStyle.notificationDismiss, {
						[notificationStyle.notificationErrorDismiss]: isError,
						[notificationStyle.notificationWarningDismiss]: isWarning,
					})}
					type="button"
					aria-label="Close"
					onClick={onDismiss}
					data-pp-at-target="Notification dismiss button"
				>
					<SvgIcon svg={closeIcon} scale={0.75} />
				</button>
			</div>
		);
	}

	private getIcon = () => {
		const { hideIcon, isWarning, isError } = this.props;

		if (hideIcon) {
			return null;
		} else if (isError) {
			return (
				<SvgIcon
					svg={alertIcon}
					className={classNames(notificationStyle.notificationIcon, notificationStyle.notificationErrorAlert)}
				/>
			);
		} else if (isWarning) {
			return (
				<SvgIcon
					svg={warningIcon}
					className={classNames(
						notificationStyle.notificationIcon,
						notificationStyle.notificationWarningAlert
					)}
				/>
			);
		} else {
			return <SvgIcon svg={tickIcon} className={notificationStyle.notificationIcon} />;
		}
	};
}

export interface IEmailVerificationWarningAction {
	label: string;
	labelParams?:IParams;
	url?: string;
	onClick?: () => void;
	debounceDelay?: number;
	actionProps?: LinkProps;
}

export interface IEmailVerificationWarning {
	items: {
		text: string;
		textParams?:IParams;
		action?: IEmailVerificationWarningAction;
	}[];
}

export const EmailVerificationWarning = (props: IEmailVerificationWarning) => (
	<>
		{props.items.map(({ text, action, textParams }, index) => (
			<React.Fragment key={index}>
				<span className={notificationStyle.emailVerificationText}>
					<Text params={textParams}>{text}</Text>
				</span>
				{action && <EmailVerificationLink key={index} {...action} />}
			</React.Fragment>
		))}
	</>
);

const EmailVerificationLink = ({
	url,
	labelParams,
	label,
	onClick,
	debounceDelay,
	actionProps,
}: IEmailVerificationWarningAction) => {
	const [disabled, setDisabled] = React.useState(false);
	const handleClick = React.useCallback(async () => {
		if (isFunction(onClick) && !disabled) {
			onClick();
			if (debounceDelay) {
				setDisabled(true);
				await wait(debounceDelay);
				setDisabled(false);
			}
		}
	}, [disabled, setDisabled, onClick, debounceDelay]);

	return (
		<Link
			className={notificationStyle.emailVerificationAction}
			acceptanceTestTargetId="Email Verification Warning"
			branded={false}
			href={url}
			onClick={handleClick}
			{...actionProps}
			disabled={disabled}
		>
			<Text params={labelParams}>{label}</Text>
		</Link>
	);
};

export const createEmailVerificationNotification = (props: IEmailVerificationWarning) =>
	new NotificationViewModel(<EmailVerificationWarning {...props} />, 500, true, undefined, false, true);
