import { inject } from 'mobx-react';
import { WebGivingUserAction } from '../webgiving-user-actions';
import { isFunction } from '../../Shared/utils/is-function';
import { UserActionState } from '../pages';

export type InjectableWebGivingProps = {
	raiseAction?: (action: WebGivingUserAction) => void;
	userActionState?: UserActionState;
};

const injectableProps: (keyof InjectableWebGivingProps)[] = [
	'raiseAction',
	'userActionState',
];

export function injectWebGivingProps<T extends IReactComponent>(target: T) {
	return inject(...injectableProps)(target);
}

export function getInjectablePropsForProvider(raiseAction: (action: WebGivingUserAction) => void): InjectableWebGivingProps {
	return {
		raiseAction,
		userActionState: undefined,
	};
}

export function getInjectablePropsForViewProvider(userActionState: UserActionState): InjectableWebGivingProps {
	return {
		userActionState,
	};
}

export function raiseAction<T extends InjectableWebGivingProps>(action: WebGivingUserAction, props: T): void {
	if (!isFunction(props.raiseAction)) {
		throw new Error(`props.userActionChannel is undefined. Are you missing @injectWebGivingProps on the component?`);
	}

	if (props.userActionState && props.userActionState.blocked) {
		return;
	}

	props.raiseAction(action);
}

export type IReactComponent<P = any> =
	| React.StatelessComponent<P>
	| React.ComponentClass<P>
	| React.ClassicComponentClass<P>;
