import { isFunction } from './is-function';

export interface ICancellablePromise<T> {
	cancel(): void;
	isCancelled(): boolean;
	onCancel(cancelHandler: null | undefined | (() => void)): void;

	chainCancel<TResult2>(onresolve: (result: T) => ICancellablePromise<TResult2>) : ICancellablePromise<TResult2>;

	//modified typings from lib.es2015.promise.d.ts
	/**
     * Attaches callbacks for the resolution and/or rejection of the Promise.
     * @param onfulfilled The callback to execute when the Promise is resolved.
     * @param onrejected The callback to execute when the Promise is rejected.
     * @returns A Cancellable Promise for the completion of which ever callback is executed.
     */
	then<TResult1 = T, TResult2 = never>(
		onfulfilled?: ((value: T) => TResult1 | PromiseLike<TResult1>) | undefined | null,
		onrejected?: ((reason: any) => TResult2 | PromiseLike<TResult2>) | undefined | null)
		: ICancellablePromise<TResult1 | TResult2>;

	/**
     * Attaches a callback for only the rejection of the Promise.
     * @param onrejected The callback to execute when the Promise is rejected.
     * @returns A Cancellable Promise for the completion of the callback.
     */
	catch<TResult = never>(onrejected?: ((reason: any) => TResult | PromiseLike<TResult>) | undefined | null): ICancellablePromise<T | TResult>;
}

/**
 * This would be a lot nicer if browsers let us extend Promise but they don't
 * http://stackoverflow.com/questions/29333540/extending-promises-in-es6
 *
 * trying to follow http://bluebirdjs.com/docs/api/cancellation.html behaviour
 */
export class CancellablePromise<T> implements ICancellablePromise<T> {
	private promise: Promise<T>;
	private cancelled = false;
	private cancelHandlers: Function[] = [];

	constructor(callback: (
		onFulfilled: (value?: T | Promise<T>) => void,
		onRejected: (error?: any) => void,
		onCancel: (cancelHandler: () => void) => void,
	) => void) {
		this.promise = new Promise<T>((resolve, reject) => callback(resolve, reject, this.onCancel));
	}

	then<TResult1 = T, TResult2 = never>(
		onFulfilled?: ((value: T) => TResult1 | PromiseLike<TResult1>) | undefined | null,
		onRejected?: ((reason: any) => TResult2 | PromiseLike<TResult2>) | undefined | null,
		onCancel?: () => void): ICancellablePromise<TResult1 | TResult2> {

		return new ChainedCancellablePromise<T, TResult1, TResult2>(
			this,
			this.promise,
			onFulfilled,
			onRejected,
			onCancel);
	}

	chainCancel<TResult2>(onresolve: (result: T) => ICancellablePromise<TResult2>) : ICancellablePromise<TResult2> {
		return this.then( result => {
			let chainedPromise = onresolve(result);
			this.onCancel(() => chainedPromise.cancel());
			return chainedPromise;
		});
	}

	catch<TResult = never>(onRejected?: ((reason: any) => TResult | PromiseLike<TResult>) | undefined | null): ICancellablePromise<T | TResult> {
		return this.then(undefined, onRejected, undefined);
	}

	cancel() {
		if (!this.cancelled) {
			this.cancelled = true;
			this.cancelHandlers.forEach(x => x());
		}
	}

	isCancelled() {
		return this.cancelled;
	}

	onCancel = (cancelHandler: (() => void)) => {
		if (!isFunction(cancelHandler)) {
			throw new Error(`cancelHandler must be a function`);
		}

		this.cancelHandlers.push(cancelHandler);
	}
}

class ChainedCancellablePromise<TOriginal, TReturnType, TError> implements ICancellablePromise<TReturnType> {
	private promise: PromiseLike<TReturnType>;

	constructor(
		private chainOwner: ICancellablePromise<any>,
		promise: PromiseLike<TOriginal>,
		onFulfilled: ((value: TOriginal) => TReturnType | PromiseLike<TReturnType>) | undefined | null,
		onRejected: ((reason: any) => TError | PromiseLike<TError>) | undefined | null,
		onCancel: null | undefined | (() => void)) {

		if (isFunction(onCancel)) {
			this.chainOwner.onCancel(onCancel);
		}

		this.promise = promise.then<TReturnType | TOriginal | null, any>(
			x => {
				if (this.isCancelled()) {
					return null;
				}
				if (onFulfilled) {
					return onFulfilled(x);
				}

				return Promise.resolve(x);
			},
			error => {
				if (this.isCancelled()) {
					return null;
				}
				if (onRejected) {
					return onRejected(error);
				}

				return Promise.reject(error);
			},
		);
	}

	then<TResult1 = TReturnType, TResult2 = never>(
		onFulfilled?: ((value: TReturnType) => TResult1 | PromiseLike<TResult1>) | undefined | null,
		onRejected?: ((reason: any) => TResult2 | PromiseLike<TResult2>) | undefined | null,
		onCancel?: () => void): ICancellablePromise<TResult1 | TResult2> {

		return new ChainedCancellablePromise<TReturnType, TResult1, TResult2>(
			this.chainOwner,
			this.promise,
			onFulfilled,
			onRejected,
			onCancel,
		);
	}

	chainCancel<TResult2>(onresolve: (result: TReturnType) => ICancellablePromise<TResult2>) : ICancellablePromise<TResult2> {
		return this.then( result => {
			let chainedPromise = onresolve(result);
			this.onCancel(() => chainedPromise.cancel());
			return chainedPromise;
		});
	}

	catch<TResult = never>(onRejected?: ((reason: any) => TResult | PromiseLike<TResult>) | undefined | null): ICancellablePromise<TReturnType | TResult> {
		return this.then(undefined, onRejected, undefined);
	}

	cancel() {
		this.chainOwner.cancel();
	}

	onCancel = (cancelHandler: () => void) => {
		this.chainOwner.onCancel(cancelHandler);
	}

	isCancelled() {
		return this.chainOwner.isCancelled();
	}
}
