import * as React from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { TextMask, TextMaskOldState, processTextMask, TextMaskState } from '../utils/text-mask';
import { isFunction } from '../../Shared/utils/is-function';
import * as ReactDOM from 'react-dom';
import { skeletonDataType } from './skeleton/skeleton';

export interface ITextMaskInputProps {
	mask: TextMask[] | ((currentValue: string) => TextMask[]);
}

export interface ITextMaskInputState {
	value: string;
}

/**
 * A higher-order component that can wrap an input or a component that passes handleKeyDown and onChange props to the input
 * Please note that input.value will be overridden.
 */
@observer
export class TextMaskInput extends React.Component<ITextMaskInputProps, ITextMaskInputState> {
	@observable
	state = {
		value: '',
	};

	private hasWarnedAboutInvalidTyping = false;
	private element: HTMLInputElement;
	private oldState: TextMaskOldState | null = null;

	UNSAFE_componentWillMount() {
		this.syncPropsWithState(this.props.mask, this.child.props.value);
	}

	UNSAFE_componentWillReceiveProps(nextProps: ITextMaskInputProps & { children?: React.ReactNode }) {
		const nextValue = (React.Children.only(nextProps.children) as React.ReactElement).props.value;
		const nextMask = nextProps.mask;

		if (this.child.props.value !== nextValue || this.props.mask !== nextMask) {
			this.syncPropsWithState(nextMask, nextValue);
		}
	}

	render() {
		const child = React.Children.only(this.props.children);

		return React.cloneElement(child as React.ReactElement, {
			value: this.state.value,
			onChange: this.handleChange,
			onKeyDown: this.handleKeyDown,
			ref: this.ref,
		});
	}

	private get child() {
		return React.Children.only(this.props.children) as React.ReactElement;
	}

	@action.bound
	private syncPropsWithState(mask: TextMask[] | ((currentValue: string) => TextMask[]), value: string | string[] | number | undefined) {

		if (typeof value !== 'string') {
			// let's treat a non-string as an empty string - still works in Prod with warning in dev
			if (!this.hasWarnedAboutInvalidTyping) {
				const error =
					new Error('Unexpected value: value must have type of string. This component only supports strings in Typescript, but getting something else. Are typings up to date?');
				window.reportUnhandledRejection(error);
				this.hasWarnedAboutInvalidTyping = true;
			}
			value = '';
		}

		const processedInput = processTextMask(getTextMask(mask, value), null, { value, caretPosition: 0 });
		this.state.value = processedInput.value;
	}

	private ref = (ref: any) => {
		const element = ReactDOM.findDOMNode(ref) as HTMLInputElement;

		// In case its translation placeholder component
		// which is rendered whilst lazy loading the language files 
		if (element && element.dataset.elementType === skeletonDataType) {
			return;
		}

		if (element && !(element instanceof HTMLInputElement)) {
			throw new Error('TextMaskInput child must render <input/> tag');
		}

		if (element && element.type === 'number') {
			throw new Error('TextMaskInput does not support input[type=number] because it does not support setSelectionRange');
		}

		this.element = element;
	}

	private handleKeyDown = (event: any) => {
		this.oldState = {
			selectionStart: this.element.selectionStart!,
			selectionEnd: this.element.selectionEnd!,
			value: this.element.value,
		};

		if (isFunction(this.child.props.onKeyDown)) {
			this.child.props.onKeyDown(event);
		}

	}

	private handleChange = (event: React.FormEvent<HTMLInputElement> | string) => {
		const onChange = this.child.props.onChange;
		let value: string;
		let hasEvent = false;

		if (typeof event === 'string') {
			value = event;
		} else {
			hasEvent = true;
			value = event.currentTarget.value;
		}

		const newState: TextMaskState = {
			caretPosition: this.element.selectionEnd!,
			value,
		};

		// this.oldState is set onKeyDown
		// sometimes the change event happens without keyDown (eg. context menu > paste)
		// in those cases we pass oldState as null
		const processedValue = processTextMask(getTextMask(this.props.mask, value), this.oldState, newState);
		this.oldState = null;

		this.element.value = processedValue.value;
		this.element.setSelectionRange(processedValue.caretPosition, processedValue.caretPosition);

		if (isFunction(onChange)) {
			onChange(hasEvent ? event : processedValue.value);
		}

		requestAnimationFrame(() => {
			if (this.state.value === processedValue.value && this.element.type !== 'number') {
				this.element.setSelectionRange(processedValue.caretPosition, processedValue.caretPosition);
			}
		});
	}
}

function getTextMask(mask: TextMask[] | ((currentValue: string) => TextMask[]), currentValue: string): TextMask[] {
	if (mask instanceof Array) {
		return mask;
	}

	return mask(currentValue);
}
