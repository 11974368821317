import * as React from 'react';
import { observer } from 'mobx-react';
import { SvgIcon, SvgProperties } from '../../../WebGiving/components/svg-icon';
import { Link } from '../../../WebGiving/primitives/link/link';
import { AppConnectMerchantViewModel} from '../../app-connect-generated';

import * as styles from './download-links.less';

import appleStoreBadge from '../../svg/apple-store-badge.svg';
import googlePlayBadge from '../../svg/google-play-badge.svg';
import windowsStoreBadge from '../../svg/windows-store-badge.svg';

@observer
export class DownloadLinks extends React.Component<{ merchant: AppConnectMerchantViewModel }, {}> {
	render() {
		const { IOSAppStoreLink: iOSAppStoreLink, GooglePlayLink: googlePlayLink, WindowsStoreLink: windowsStoreLink } = this.props.merchant;
		return (
			<div className={styles.downloadLinks}>
				<DownloadLink downloadUrl={iOSAppStoreLink} svg={appleStoreBadge} acceptanceTestTargetId="Download Apple Store" />
				<DownloadLink downloadUrl={googlePlayLink} svg={googlePlayBadge} acceptanceTestTargetId="Download Play Store" />
				<DownloadLink downloadUrl={windowsStoreLink} svg={windowsStoreBadge} acceptanceTestTargetId="Download Windows Store" />
			</div>
		);
	}
}

@observer
export class DownloadLink extends React.Component<{ downloadUrl: string, acceptanceTestTargetId: string, svg: SvgProperties}, {}> {
	render() {
		const { downloadUrl, acceptanceTestTargetId, svg } = this.props;
		if (!downloadUrl) {
			return null;
		}
		return (
			<Link className={styles.downloadLink} href={downloadUrl} acceptanceTestTargetId={acceptanceTestTargetId} branded={false} openInNewTab={true}>
				<SvgIcon svg={svg} />
			</Link>
		);
	}
}
