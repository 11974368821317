import * as React from 'react';
import { classNames } from '../../Shared/utils/classnames';

export interface SvgProperties {
	/**
	 * The Id of the icon in the SVG Sprite
	 */
	id: string;

	/**
	 * The viewbox string of the icon, typically "0 0 {width} {height}"
	 */
	viewBox: string;
}

export interface SvgIconProps {
	/**
	 * SVG Icon properties populated when importing SVG Icon
	 */
	svg: SvgProperties;

	/**
	 * Used to increase the size of the icon. Should be 1 or multiple of 2 to avoid sub-pixel rendering
	 */
	scale?: number;

	/**
	 * Do not use
	 * Being removed soon because firefox does not allow for inline style.
	 */
	style?: React.CSSProperties;

	/**
	 * Any custom css classes. SVG will contain `svg-icon` plus any class names added here
	 */
	className?: string;

	/**
	 * Accessible label for SVG icon. Like the alt text for an image.
	 */
	ariaLabel?: string;
}

export class SvgIcon extends React.Component<SvgIconProps & React.HTMLProps<SVGSVGElement>, {}> {
	render() {
		const {svg: {id, viewBox}, scale, style, className, ariaLabel, ...rest} = this.props;
		const currentScale = scale || 1;

		const viewBoxParts = viewBox.split(' ');
		const newWidth = parseInt(viewBoxParts[2], 10) * currentScale;
		const newHeight = parseInt(viewBoxParts[3], 10) * currentScale;

		const attributes = {
			viewBox,
			focusable: 'false',
			className: classNames('svg-icon', className),
			width: '',
			height: '',
			style: {},
			'aria-label': ariaLabel,
			role: (ariaLabel ? 'img' : 'presentation'),
		};

		attributes.width = `${newWidth}px`;
		attributes.height = `${newHeight}px`;

		return (
			<svg {...rest} {...attributes}>
				{ariaLabel && <title>{ariaLabel}</title>}
				<use xlinkHref={`#${id}`} />
			</svg>
		);
	}
}
