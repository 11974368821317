import * as React from 'react';
import { isFunction } from '../../Shared/utils/is-function';

export class Form extends React.Component<{
	className?: string;
	onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
}, {}> {
	render() {
		const { children, className } = this.props;

		return <form className={className} onSubmit={this.handleOnSubmit} noValidate>{children}</form>;
	}

	handleOnSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		const { onSubmit } = this.props;

		event.preventDefault();

		if (isFunction(onSubmit)) {
			onSubmit(event);
		}
	}
}
