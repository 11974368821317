
export enum ModalType {
	NeedHelp,
	MobileSignInInfo,
	PushpayPrivacy,
	MerchantPrivacy,
	PushpayTerms,
	MerchantTerms,
	FailedToResolveFund,
	InvalidPreconfiguredLink,
	Crypto,
}
