import * as mobx from 'mobx';
import moment from 'moment';

const serializedDateFormat = 'YYYY-MM-DDTHH:mm:ss';
declare const STORYBOOK: boolean;
declare const UNIT_TEST: boolean;

export module AjaxUtils {
	const dateReviverRegex = /^\/Date\((.*)\)/;

	export function reviveDate(key: any, value: any) {
		if (typeof value === 'string') {
			const ticks = dateReviverRegex.exec(value);
			if (ticks) {
				const serializedUtcDate = moment.utc(parseInt(ticks[1])).format(serializedDateFormat);
				return moment(serializedUtcDate).toDate();
			}
		}
		return value;
	}

	export function dictionaryToUrlEncodedFormString(d: { [s: string]: string }): string {
		const flat = flatten(d);
		return Object.getOwnPropertyNames(flat)
			.map(x => ({ key: x, value: flat[x] }))
			.filter(x => x.value !== null && x.value !== undefined)
			.map(x => `${encodeURIComponent(x.key)}=${encodeURIComponent(x.value)}`).join('&');
	}

	export function combineWithBasePath(url: string, basePath: string | undefined | null) {
		if (!basePath) {
			return url;
		}
		return basePath.replace(/\/?$/, '/') + url.replace(/^\/?/, '');
	}

	export function resolveBaseUrl(baseUrl: () => string) {
		try {
			return baseUrl();
		} catch (ex) {
			if (UNIT_TEST || STORYBOOK) {
				return '/';
			}
			throw new Error(`Error resolving baseUrl: ${ex}`);
		}
	}

	//http://stackoverflow.com/questions/19098797/fastest-way-to-flatten-un-flatten-nested-json-objects
	function flatten(data: any) {
		var result: any = {};
		function recurse(cur: any, prop: string) {
			if (cur instanceof Date) {
				result[prop] = moment(cur).format(serializedDateFormat);
			} else if (Object(cur) !== cur) {
				result[prop] = cur;
			} else if(mobx.isObservable(cur)) {
				recurse(mobx.toJS(cur), prop);
			} else if (Array.isArray(cur)) {
				for (var i = 0, length = cur.length; i < length; i++) {
					recurse(cur[i], prop ? prop + '[' + i + ']' : '' + i);
				}

			} else {
				var isEmpty = true;
				for (var p in cur) {
					isEmpty = false;
					recurse(cur[p], prop ? prop + '.' + p : p);
				}
				if (isEmpty) {
					result[prop] = {};
				}
			}
		}
		recurse(data, '');
		return result;
	}
}
