import * as React from 'react';

import { isFunction } from '../../Shared/utils/is-function';
import { inputPropsToShowNumericKeyboard } from '../../Shared/utils/input-utils';
import { classNames } from '../../Shared/utils/classnames';
import { useTranslation, ShouldTranslate } from '../translation';
import { Skeleton } from '../components/skeleton/skeleton';

export interface ITextboxCommonProps {
	acceptanceTestTargetId: string;
	formControlId?: string;
	onFocus?: (target?: React.FocusEvent<HTMLInputElement>) => void;
	placeholder?: string;
	onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
	additionalClassNames?: string;
	disableTranslation?: boolean;
	type?: string;
	labelledBy?: string;
	maxLength?: number;
	dataFieldInvalid?: boolean;
	numericKeyboard?: boolean;
	name?: string;
	autoComplete?: string;
	pattern?: string;
	ariaDescribedBy?: string;
}

export interface ITextboxPrimitiveProps {
	value: string;
	onChange?: (value: string) => void;
	onBlur?: () => void;
	'aria-required'?: boolean;
}

export type ITextboxProps = ITextboxCommonProps & ITextboxPrimitiveProps;

export class Textbox extends React.Component<ITextboxProps, {}> {
	input: HTMLInputElement;

	render() {
		const {
			formControlId,
			disableTranslation = false,
			acceptanceTestTargetId = null,
			type = 'text',
			additionalClassNames,
			labelledBy,
			ariaDescribedBy,
			dataFieldInvalid,
			numericKeyboard,
			'aria-required': ariaRequired,
			...rest } = this.props;

		const classes = classNames('form-control', additionalClassNames);

		return (
			<ShouldTranslate>
				{(translate: boolean) =>
					translate && !disableTranslation ? (
						<React.Suspense fallback={ <Skeleton /> }>
							<TranslatedIput
								className={classes}
								{...rest}
								type={type}
								handleChange={this.handleChange}
								handleBlur={this.handleBlur}
								acceptanceTestTargetId={this.props.acceptanceTestTargetId}
								ariaRequired={ariaRequired}
							/>
						</React.Suspense>
					):(
						<input
							className={classes}
							{...rest}
							type={type}
							spellCheck={false}
							id={formControlId}
							onChange={this.handleChange}
							onBlur={this.handleBlur}
							data-pp-at-target={acceptanceTestTargetId}
							aria-labelledby={labelledBy}
							aria-describedby={ariaDescribedBy}
							aria-required={ariaRequired}
							name={name}
							{...(numericKeyboard ? inputPropsToShowNumericKeyboard() : undefined)}
							data-field-invalid={dataFieldInvalid ? true : null}
						/>
					)
				}
			</ShouldTranslate>
		);
	}

	private handleChange = (event: React.FormEvent<HTMLInputElement>) => {
		const { onChange } = this.props;

		if (isFunction(onChange)) {
			onChange(event.currentTarget.value);
		}
	}

	private handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
		const { onBlur } = this.props;

		if (isFunction(onBlur)) {
			onBlur();
		}
	}
}

export const TranslatedIput: React.FC<
	ITextboxProps & {
		className: string;
		ariaRequired: boolean | undefined;
		handleChange: (event: React.FormEvent<HTMLInputElement>) => void;
		handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
	}
> = (props) => {
	const { translate } = useTranslation();
	const {
		placeholder,
		formControlId,
		handleChange,
		handleBlur,
		acceptanceTestTargetId,
		labelledBy,
		ariaDescribedBy,
		ariaRequired,
		numericKeyboard,
		...rest
	} = props;

	return (
		<input
			{...rest}
			spellCheck={false}
			placeholder={!!placeholder ? translate(placeholder as any) : undefined}
			id={formControlId}
			onChange={handleChange}
			onBlur={handleBlur}
			data-pp-at-target={acceptanceTestTargetId}
			aria-labelledby={labelledBy}
			aria-describedby={ariaDescribedBy}
			aria-required={ariaRequired}
			name={name}
			{...(numericKeyboard
				? inputPropsToShowNumericKeyboard()
				: undefined)}
			data-field-invalid={props.dataFieldInvalid ? true : null}
		/>
	);
};


