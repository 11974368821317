import * as ua from 'ua-parser-js';


let cachedPropsForNumericKeyboard: { type?: string, pattern?: string };

export function inputPropsToShowNumericKeyboard(): { type?: string, pattern?: string } {
	if (!cachedPropsForNumericKeyboard) {
		const os = new ua.UAParser(window.navigator.userAgent).getOS();
		let result: { type?: string, pattern?: string };

		if (os.name === 'Android') {
			result = { type: 'tel' };
		} else if (os.name === 'iOS') {
			result = { pattern: '\\d*' };
		} else {
			result = {};
		}

		cachedPropsForNumericKeyboard = result;
	}

	return cachedPropsForNumericKeyboard;
}
