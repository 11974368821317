import * as React from 'react';
import { observer } from 'mobx-react';
import { ISelectCommonProps, Select } from '../../primitives/select';
import { FormField } from '../../components/form-field';
import { isFunction } from '../../../Shared/utils/is-function';

export type ISelectFieldProps<T> = ISelectCommonProps<T> & {
	formField: FormField<T>,
	onChange?: () => void,
	onClick?: () => void,
	onBlur?: () => void
};

@observer
export class SelectField extends React.Component<ISelectFieldProps<any>, {}> {

	render() {
		const {
			formField: { value },
			onClick,
			onBlur,
			...rest
		} = this.props;

		return (
			<Select
				{...rest}
				value={value}
				onChange={this.handleChange}
				onClick={onClick}
				onBlur={onBlur}
			/>
		);
	}

	handleChange = (value: any) => {
		const { updateValue, revalidate } = this.props.formField;
		updateValue(value);
		revalidate();

		if (isFunction(this.props.onChange)) {
			this.props.onChange();
		}
	}
}
