import { CardBrandData, CardBrandConfig } from '../webgiving-generated';

export const baseCardBrandIconsPath = 'paymentmethod-';
export const cardBrandIconsPath = 'paymentmethod-brand-';

export function getCardBrandByInput(input: string) {
	for (let b of CardBrandData) {
		if (b.pattern.test(input)) {
			return b;
		}
	}
	return null;
}

export function getCardBrandByKey(key: string) {
	for (let b of CardBrandData) {
		if (b.key === key) {
			return b;
		}
	}

	return null;
}

export function getCardBrandsFromKeys(brandKeys: string[]) {
	return brandKeys.map(getCardBrandByKey).filter((x) => x) as CardBrandConfig[];
}

export const getSupportedCardIconParams = (minimize?: boolean) => {
	return CardBrandData.reduce((res, curr) => ({
		...res, [curr.key]: { scale: getIconScale(curr.shortName.toLowerCase(), minimize) }
	}), {
		ach: { scale: getIconScale('ach', minimize) },
		applepay: { scale: getIconScale('applepay', minimize) }
	});
};

export const getIconScale = (name: string, minimize?: boolean) => {
	switch (name.toLowerCase()) {
		case 'amex':
		case 'discover':
			return minimize ? 0.8 : 1;
		case 'mastercard':
			return minimize ? 0.56 : 0.67;
		case 'visa':
			return minimize ? 0.5 : 0.67;
		case 'applepay':
			return minimize ? 0.67 : 1;
		default:
			return 1;
	}
}

